import { useState, useEffect } from "react";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

import cn from "../../../utilities/cn";
import { TeamData } from "../../../config/const";

import Arrow from "../../../assets/icons/arrow";

import NextIcon from "../../../assets/icons/next";
import PrevIcon from "../../../assets/icons/prev";

import "swiper/css";
import "swiper/css/navigation";
import { useSelector } from 'react-redux';

export default function TeamSection() {
  const [selectedTeam, setSelectedTeam] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const [centeredSlides, setCenteredSlides] = useState(false);
  const [hideArrows, setHideArrows] = useState(false);

  const { t } = useTranslation();
  const theme = useSelector((state) => state.theme.mode);

  const checkArrows = (swiperInstance) => {
    if (!swiperInstance) return;
    if (swiperInstance.isLocked) {
      setHideArrows(true);
    } else {
      setHideArrows(false);
    }
  };

  useEffect(() => {
    if (swiper) {
      checkArrows(swiper);

      const handleResize = () => checkArrows(swiper);
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [swiper]);

  useEffect(() => {
    const updateCentering = () => {
      const slides = TeamData.members[selectedTeam].members.length;
      const viewWidth = window.innerWidth;
      let slidesPerView = 2;
      if (viewWidth >= 1370) slidesPerView = 5;
      else if (viewWidth >= 1100) slidesPerView = 4;
      else if (viewWidth >= 820) slidesPerView = 3;
      setCenteredSlides(slides < slidesPerView);
    };

    updateCentering();
    window.addEventListener('resize', updateCentering);

    return () => window.removeEventListener('resize', updateCentering);
  }, [selectedTeam]);

  return (
    <section className="section relative !p-0 md:w-[80vw] sm:w-[80vw]" id="team">
      <img
        src={'/images/bg/TeamBG.webp'}
        alt=""
        className="hidden md:hidden dark:md:block object-cover object-center"
      />
      <img
        src={'/images/bg/TeamBGLight.webp'}
        alt=""
        className="hidden md:block dark:md:hidden object-cover object-center"
      />
      <img
        src={theme === "light" ? '/images/bg/TeamBGMobileLight.webp' : '/images/bg/TeamBGMobile.webp'}
        alt=""
        className="md:hidden dark:md:hidden w-full object-cover object-center"
      />
      <img
        src={'/images/bg/TeamLines.webp'}
        alt=""
        className="absolute top-1/2 -translate-y-1/2 -z-10 object-cover object-center w-[77.4vw]  hidden md:hidden dark:md:block"
      />
      <img
        src={'/images/bg/TeamLinesLight.webp'}
        alt=""
        className="absolute top-1/2 -translate-y-1/2 -z-10 object-cover object-center hidden md:block dark:md:hidden w-[77.4vw]"
      />
      <img
        src={theme === "light" ? '/images/bg/TeamLinesMobileLight.webp' : '/images/bg/TeamLinesMobile.webp'}
        alt=""
        className="absolute top-1/2 -translate-y-1/2 -z-10 w-full object-cover object-center hidde md:hidden dark:md:hidden"
      />
      <div className="absolute grid gap-12 md:gap-4 w-full top-1/2 -translate-y-1/2">
        <div className="flex overflow-auto scrollbar-none mx-auto p-2 gap-[2px] md:gap-[17px] rounded-full dark:bg-[#0C0C0F] bg-[#F3F3F3] w-fit">
          {TeamData.members.map(({ type }, index) => (
            <button
              className={cn(
                "text-[12px] leading-[12px] md:text-[16px] md:leading-[16px] font-semibold rounded-full px-3 py-3 md:py-4 md:px-5 w-fit transition-all hover:bg-black/5 hover:dark:bg-white/5 hover:!text-black hover:dark:!text-white",
                index === selectedTeam &&
                "bg-theme-orange text-white dark:text-white hover:text-theme-bg"
              )}
              key={index}
              onClick={() => setSelectedTeam(index)}>
              {t(type)}
            </button>
          ))}
        </div>
        <Swiper
          spaceBetween={1}
          onSwiper={swiper => setSwiper(swiper)}
          slidesPerView={2}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            820: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
            1100: {
              slidesPerView: 4,
              spaceBetween: 1,
            },
            1370: {
              slidesPerView: 5,
              spaceBetween: 1,
            },
          }}
          className={!centeredSlides ? "mySwiper" : "mySwiper centeredSwiper"}>
          {TeamData.members[selectedTeam].members.map(
            ({ name, href, job, icon }, index) => (
              <SwiperSlide key={index}>
                <MemberCard
                  name={name}
                  href={href}
                  job={job}
                  icon={icon}
                  key="index"
                />
              </SwiperSlide>
            )
          )}
        </Swiper>

        <div className={`flex gap-4 mx-auto ${hideArrows ? 'hidden' : ''}`}>
          <button
            onClick={() => swiper.slidePrev()}
            className="rounded-full h-12 w-12 flex justify-center items-center bg-white/5 border fill-[#999999] border-[#999999] transition-all hover:border-theme-orange hover:fill-theme-orange">
            <PrevIcon />
          </button>
          <button
            onClick={() => swiper.slideNext()}
            className="rounded-full h-12 w-12 flex justify-center items-center bg-white/5 border fill-[#999999] border-[#999999] transition-all hover:border-theme-orange hover:fill-theme-orange">
            <NextIcon />
          </button>
        </div>
      </div>
    </section>
  );
}

function MemberCard({ name, href, job, icon }) {
  return (
    <div
      className="p-[1.5px] rounded-xl snap-center text-white hover:cursor-pointer"
      style={{
        backgroundImage: `radial-gradient(163.27% 64.37% at 121.3% 37.79%, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0)), radial-gradient(at left bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)), radial-gradient(at top, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))`,
      }}>
      <div className="h-[280px] w-[165.5px] md:h-[312px] md:w-[212px] p-3 rounded-xl flex flex-col items-center justify-center shrink-0 dark:bg-[#0C0C0F] bg-[#F3F3F3]">
        <div className="flex-1">
          <img src={icon} alt="" className="!h-[144px] md:!h-[220px] w-full object-cover" loading="lazy" />
        </div>
        <a className="pt-6 w-[90%]" href={href} target="_blank" rel="noopener noreferrer">
          <p className="font-semibold text-lg text-left dark:text-white text-black">
            {name}
          </p>
          <div className="flex text-left md:text-center md:justify-between">
            <p className="dark:text-white/60 text-black/80 text-xs text-left">{job}</p>
            <span>
              <Arrow className="hover:dark:fill-white hover:fill-theme-bg fill-theme-orange" />
            </span>
          </div>
        </a>
      </div>
    </div>
  );
}
