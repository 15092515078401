import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";

export default function Accordion({ question, answer, index, expanded }) {
  const [isOpen, setOpen] = useState(expanded);
  // Update isOpen when the expanded prop changes
  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);
  return (
    <div className="rounded-2xl p-4 dark:bg-[#14141880] bg-[#14141810] text-left text-white group">
      <button
        onClick={() => setOpen(open => !open)}
        className="flex w-full justify-between text-base font-bold"
        aria-expanded={isOpen}
      >
        <span className="w-[70vw] text-left md:w-full dark:text-white text-black">{question}</span>
        <span
          className={clsx(
            "rounded-[5px] w-6 h-6 transition-all group-hover:bg-theme-orange/20",
            isOpen
              ? "bg-theme-orange text-white"
              : "dark:bg-[#0C0C0F] bg-[#FFFFFF] text-theme-orange"
          )}>
          {isOpen ? "-" : "+"}
        </span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}>
            <p className="dark:text-[#BBBBBB] text-black/80 mt-2">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
