import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Drawer from "./components/Header/Drawer";
import Header from "./components/Header";
import Footer from "./components/Footer";

const pagesOutOfLayout = ["bbwdubai"];

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {pathname} = useLocation();

  return (
    <>
      <div>
        {!pagesOutOfLayout.includes(pathname.split('/')[1]) ?
          <>
            <Header setIsOpen={setIsOpen} />
            <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
            {children}
            <Outlet />
            <Footer />
          </>
          :
          <>{children}</>
        }
      </div>
    </>
  );
};

export default Layout;
