import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";

export default function TopArea() {
  const { t, i18n } = useTranslation();
  return (
    <div className="grid gap-4 md:gap-6 mt-5 px-[25px]">
      <h1 className="leading-[35.2px] md:leading-[63px] font-bold text-[32px] sm:text-[32px] md:text-[48px] lg:text-[64px]">
          {t("welcome-brighter")}
        <br />
        <span style={{ color: "rgb(237 92 21)" }}>
          {t("blockchain-solutions")}
        </span>
      </h1>
      <p className="font-normal text-base md:block hidden w-[33.875vw] mx-auto">
        {t("our-mission-is-top")}
      </p>
      <p className="font-normal text-base leading-[18.2px] text-[14px] md:hidden">
        {t("our-mission-is-top")}
      </p>
      <Button 
        type="a"
        href="https://t.me/SolarEnterprises"
        target="_blank"
        rel="noopener noreferrer"
        hoverImg={i18n.language === "jp"? "/images/shapes/solar-innovation-hover.svg": "/images/shapes/join-community-hover.svg"}
      >
        {t("join-community")}
        <span>
          <img src={'/images/icons/arrow-up-right.svg'} alt="" className="bg-white rounded-full" />
        </span>
      </Button>
    </div>
  );
}
