export default function DiscordIcon(args) {
  return (
    <svg 
      width="40" 
      height="40" 
      viewBox="0 0 40 40" 
      fill="none"
      {...args} 
      xmlns="http://www.w3.org/2000/svg">
      <path d="M27.2925 13.3513C29.6495 16.8381 30.8135 20.7712 30.3783 25.299C30.3765 25.3182 30.3666 25.3358 30.351 25.3473C28.5661 26.666 26.8368 27.4663 25.1317 27.9971C25.1185 28.0011 25.1043 28.0009 25.0911 27.9964C25.078 27.992 25.0666 27.9835 25.0584 27.9721C24.6645 27.4207 24.3066 26.8393 23.9931 26.2287C23.9751 26.1928 23.9915 26.1495 24.0285 26.1353C24.597 25.9198 25.1375 25.6615 25.6574 25.3557C25.6984 25.3316 25.701 25.2725 25.6631 25.2442C25.5528 25.1617 25.4435 25.0751 25.3389 24.9885C25.3193 24.9725 25.293 24.9693 25.2708 24.9801C21.8954 26.5484 18.1978 26.5484 14.7825 24.9801C14.7604 24.9701 14.734 24.9735 14.715 24.9893C14.6106 25.0759 14.5011 25.1617 14.3918 25.2442C14.3539 25.2725 14.3571 25.3316 14.3983 25.3557C14.9182 25.6557 15.4587 25.9198 16.0264 26.1363C16.0632 26.1505 16.0806 26.1928 16.0624 26.2287C15.7556 26.8401 15.3977 27.4215 14.9965 27.9729C14.979 27.9952 14.9503 28.0055 14.9232 27.9971C13.2262 27.4663 11.4969 26.666 9.71203 25.3473C9.69716 25.3358 9.68647 25.3174 9.6849 25.2982C9.32125 21.3817 10.0624 17.4161 12.7679 13.3505C12.7744 13.3398 12.7843 13.3314 12.7958 13.3264C14.127 12.7116 15.5532 12.2594 17.0438 12.0011C17.0709 11.9969 17.098 12.0095 17.1121 12.0337C17.2963 12.3618 17.5068 12.7825 17.6492 13.1264C19.2205 12.8849 20.8162 12.8849 22.4203 13.1264C22.5627 12.7899 22.7659 12.3618 22.9493 12.0337C22.9558 12.0217 22.966 12.0121 22.9782 12.0062C22.9905 12.0004 23.0043 11.9986 23.0177 12.0011C24.5091 12.2602 25.9352 12.7124 27.2654 13.3264C27.2771 13.3314 27.2868 13.3398 27.2925 13.3513ZM18.448 20.798C18.4645 19.6401 17.6255 18.6821 16.5724 18.6821C15.5278 18.6821 14.697 19.6317 14.697 20.798C14.697 21.9639 15.5443 22.9136 16.5724 22.9136C17.6172 22.9136 18.448 21.9639 18.448 20.798ZM25.3824 20.798C25.3989 19.6401 24.5599 18.6821 23.5071 18.6821C22.4623 18.6821 21.6314 19.6317 21.6314 20.798C21.6314 21.9639 22.4787 22.9136 23.5071 22.9136C24.5599 22.9136 25.3824 21.9639 25.3824 20.798Z"/>
    </svg>
  );
}
