import { SocialLinkData } from "../../../config/const";

import footerSolarEnterprisesImage from "../../../assets/icons/footer-solar-enterprises.png";

const Footer = () => {
  return (
    <div className="w-full px-[24px] mt-[120px] pb-[80px] flex flex-col justify-center items-center">
      <div className="w-full max-w-[1738px]">
        <div className="flex flex-col md:flex-row justify-between items-center gap-5">
          <div className="flex flex-col gap-[12px] self-start md:order-1 order-2">
            <a href="mailto:info@solarenterprises.com">
              <p className="text-[16px] text-white">info@solarenterprises.com</p>
            </a>
            <div className="flex flex-row gap-[8px] justify-center">
              {SocialLinkData.map((data, index) => (
                <a key={`social-link-${index}`} target="_blank" href={data.link} rel="noreferrer">
                  <img src={data.image} className="cursor-pointer" />
                </a>
              ))}
            </div>
          </div>

          <div className="flex flex-col md:self-center self-start md:order-2 order-1">
            <a href="/">
              <img src={footerSolarEnterprisesImage} className="w-[240px] h-[61px]" />
            </a>
          </div>

          <div className="flex flex-col gap-[12px] mt-2 self-start md:order-3 order-3">
            <p className="text-[16px] text-[#828282] text-left md:text-right">&copy; 2024 Solar Enterprises</p>
            <p className="text-[16px] text-[#828282] text-left md:text-right">All rights reserved.</p>
          </div>
        </div>
        
        <div className="flex flex-row gap-[24px] w-full items-center md:justify-center justify-start mt-[34px]">
          {/* <p className="text-[12px] text-[#828282] cursor-pointer underline" onClick={() => window.open(DocLinkData.termsOfService, "_blank")}>
            Terms of Service
          </p> */}
          <a href="https://solarenterprises.com/privacy">
            <p className="text-[16px] text-[#828282] cursor-pointer underline">
              Privacy Policy
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
