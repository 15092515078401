const Visit = () => {
  return (
    <div className="w-full mt-[120px] flex flex-col justify-center items-center">
      <div className="w-full max-w-[1743px] md:py-[60px] py-[30px] md:px-[12px] px-[40px] flex flex-col justify-center items-center bg-[#141418]">
        <div className="max-w-[840px]">
          <p className="text-white text-[24px] lg:text-[48px] font-bold text-center lg:leading-[56px] leading-[31px]">
          Visit our booth S13 at the Coca Cola Arena, City Walk, Dubai, and join us in shaping the future!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Visit;
