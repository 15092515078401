import { useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import { ProductData } from "../../../config/const";

const Product = () => {
  const [mediaData, setMediaData] = useState();

  useEffect(() => {
    fetch("/solar-media-list.json").then(async (res) => {
      const data = await res.json();
      setMediaData(data);
    })
  }, []);

  return (
    <>
      {mediaData && 
        <>
          {mediaData.banner.video.visibility && 
            <div className="w-full px-[24px] mt-[20px]">
              <video controls autoPlay width="100%" height="100%" poster="/solar-media-poster.png" className="rounded-[30px]">
                  <source src={mediaData.banner.video.mp4} type="video/mp4" />
              </video>
            </div>
          }
          <div className="lg:hidden w-full px-[24px] mt-[20px] flex flex-wrap lg:justify-between justify-center gap-[30px]">
            {ProductData.map((data, index) => (
              <ProductCard mediaData={mediaData} key={`product-card-${index}`} data={data} />
            ))}
          </div>
          <div className="w-full px-[24px] mt-[20px] lg:flex hidden lg:flex-nowrap flex-wrap lg:justify-between justify-center gap-[30px]">
            {ProductData.slice(0,3).map((data, index) => (
              <ProductCard mediaData={mediaData} wide className="lg:max-w-none w-full" key={`product-card-${index}`} data={data} />
            ))}
          </div>
          <div className="w-full px-[24px] mt-[30px] lg:flex hidden lg:flex-nowrap flex-wrap lg:justify-between justify-center gap-[30px]">
            {ProductData.slice(-2).map((data, index) => (
              <ProductCard mediaData={mediaData} wide className="lg:max-w-none w-full" key={`product-card-${index}`} data={data} />
            ))}
          </div>
        </>
      }
    </>
  );
};

export default Product;
