import clsx from "clsx";
import { useRef, useState } from "react";
import { useInView, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { JoinCommunityData } from "../../../config/const";

import GlobalIcon from "../../../assets/icons/global";

import cn from "../../../utilities/cn";
import { useSelector } from 'react-redux';

export default function JoinCommunitySection() {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const { t } = useTranslation();
  const theme = useSelector((state) => state.theme.mode);
  const [isFocused, setIsFocused] = useState(false);
  return (
    <section className="containerAlt px-5 py-5 md:px-36 md:py-20 w-full">
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={inView ? { scale: 1, opacity: 1 } : {}}
        transition={{ duration: 1 }}
        className="dark:bg-theme-bg bg-[#EFEFEF] rounded-[32px] relative overflow-hidden"
        ref={ref}>
        <div className="w-full h-full relative z-20 rounded-[32px] overflow-hidden dark:bg-[linear-gradient(180deg,_rgba(20,_20,_24,_0)_0%,_#141418_68.5%)] bg-[linear-gradient(180deg,_rgba(239,_239,_239,_0)_0%,_#EFEFEF_68.5%)] text-white">
          <div className="py-[35px] px-[20px] md:py-16 md:px-8 grid gap-10 md:gap-0 lg:grid-cols-2">
            <div className="lg:max-w-[450px]">
              <h3 className="font-bold text-[32px] leading-[35.2px] md:text-[48px] md:leading-[47.52px] mb-5 dark:text-white text-black">
                {t("join-community-title")}
              </h3>
              <p className="font-normal text-[14px] leading-[18.2px] md:text-[16px] md:leading-[20. 8px] text-base dark:text-white text-black">
                {t("join-community-desc")}
              </p>
            </div>
            <div className="h-full grid items-end">
              <div className="relative p-2 pl-4 mt-auto border border-black/10 animate-input dark:border-white/10 h-[64px] rounded-full dark:bg-theme-bg bg-[#E8E8E8] flex">
                <input
                  type="text"
                  className="bg-transparent outline-none w-full hover:placeholder:text-white dark:text-white text-black z-[1]"
                  placeholder={isFocused ? '' : t("join-community-input")}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
                <button className="bg-theme-orange font-semibold text-base rounded-full py-2 px-5 z-[1] word-break-keepAll">
                  {t("join-community-btn")}
                </button>
                <div style={{ display: isFocused?`block`:"none"}} className={`absolute top-[-9px] z-0 left-[-8px] h-[80px] bg-no-repeat myfocuse`}>
                  <div className={'w-[80%] h-[100%] overflow-hidden absolute top-0 left-0 z-[1]'}>
                    <svg width="619" height="80" viewBox="0 0 619 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.5" y="1.5" width="616" height="77" rx="38.5" stroke="url(#paint0_linear_771_10804)" strokeWidth="3"/>
                      <defs>
                      <linearGradient id="paint0_linear_771_10804" x1="8.32735" y1="2.07003e-06" x2="70.9572" y2="140.536" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#ED5C15"/>
                      <stop offset="1" stopColor="#ED5C15" stopOpacity="0"/>
                      </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className={'w-[80%] h-[100%] overflow-hidden z-0  absolute top-0 right-0'}>
                    <svg className={'absolute top-0 right-0'} width="619" height="80" viewBox="0 0 619 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.5" y="1.5" width="616" height="77" rx="38.5" stroke="#ED5C15" strokeOpacity="0.25" strokeWidth="3"/>
                      <defs>
                      <linearGradient id="paint0_linear_771_10804" x1="8.32735" y1="2.07003e-06" x2="70.9572" y2="140.536" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#ED5C15"/>
                      <stop offset="1" stopColor="#ED5C15" stopOpacity="0"/>
                      </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-3">
            {JoinCommunityData.communities.map(
              ({ name, desc, href, icon, iconLight }, index) => (
                <CommunitiesCard
                  name={name}
                  desc={desc}
                  href={href}
                  icon={icon}
                  iconLight={iconLight}
                  index={index}
                  key={index}
                />
              )
            )}
          </div>
        </div>
        <img
          src={"/images/bg/JoinCommunity.webp"}
          alt=""
          className="absolute w-full h-full z-10 object-cover top-0 left-0 hidden md:hidden dark:md:block"
        />
        <img
          src={"/images/bg/JoinCommunityLight.webp"}
          alt=""
          className="absolute w-full h-full z-10 object-cover top-0 left-0 hidden md:block dark:md:hidden"
        />
        <img
          src={theme === "light" ? "/images/bg/JoinCommunityMobileLight.webp": "/images/bg/JoinCommunityMobile.webp"}
          alt=""
          className="absolute w-full h-full z-10 object-cover top-0 left-0 md:hidden dark:md:hidden"
        />
      </motion.div>
    </section>
  );
}

function CommunitiesCard({ name, desc, href, icon, iconLight, index }) {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={clsx(
        "flex flex-col border-t border-r dark:border-white/10 border-black/10 md:p-8 p-5",
        index !== 0 && index % 2 === 0 && "border-r-0"
      )}>
      <img
        src={icon}
        alt=""
        className="w-8 h-8 dark:block hidden"
        width={32}
        height={32}
      />
      <img
        src={iconLight}
        alt=""
        className="w-8 h-8 dark:hidden"
        width={32}
        height={32}
      />
      <span className="opacity-50 text-sm font-normal dark:text-white text-black my-8">
        {t(desc)}
      </span>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onMouseOver={() => {setIsHover(true)}}
        onMouseLeave ={() => {setIsHover(false)}}
        className={cn(
          "w-full dark:bg-[#0C0C0F] bg-[#E8E8E8] rounded-full dark:text-white text-black font-bold py-[14px] px-4 flex justify-between items-center cursor-pointer ",
          "dark:hover:!bg-theme-orange hover:!bg-theme-bg hover:text-white"
        )}>
        {t(name)}
        <GlobalIcon 
          mode="dark"
          className={"dark:block hidden"}
        />
        {!isHover?
          <GlobalIcon 
            mode="light"
            className={"dark:hidden"}
          />
          :
          <GlobalIcon 
            mode="light"
            isHover={true}
            className={"dark:hidden"}
          />
        }
      </a>
    </div>
  );
}
