import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HeroBackground from "../../../assets/backgrounds/hero.svg";

export default function EnterEmail() {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="text-center py-8">
      <img
        src={HeroBackground}
        alt=""
        className="absolute w-full -z-10 object-cover top-0 left-0 md:block hidden"
      />
      <h1 className="font-nunito lg:text-6xl lg:leading-99 tracking--0.64 lg:font-normal text-[32px] md:text-[48px] lg:text-[64px]">
        <span>Welcome to</span>
        <br />
        <span style={{ color: "rgb(237 92 21)" }} className="font-bold">
          Brighter Blog
        </span>
      </h1>
      <div className="flex items-center justify-center pt-6 px-5">
        <div className="p-2 pl-4 mt-auto w-[603px] border border-black/10 animate-input dark:border-white/10 h-[64px] rounded-full dark:bg-theme-bg bg-[#E8E8E8] flex">
          <input
            type="text"
            className="bg-transparent outline-none w-full hover:placeholder:text-white dark:text-white text-black z-[1]"
            placeholder={isFocused ? "" : t("join-community-input")}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <button className="bg-theme-orange font-semibold text-base rounded-full py-2 px-5 z-[1] word-break-keepAll">
            {t("join-community-btn")}
          </button>
        </div>
      </div>
    </div>
  );
}
