import GooglePlayImage from "../../../assets/icons/google-play.png";
import AppStoreImage from "../../../assets/icons/app-store.png";
import AppStoreComingImage from "../../../assets/icons/app-store-coming.png";
import PlayIcon from "../../../assets/icons/play-icon.png";
import ExertnalArrowSVG from "../../../assets/svg/external-arrow.svg";
import { useEffect, useRef } from "react";

const ProductCard = ({ className = "", data, wide = false, mediaData }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        videoRef.current?.classList.add("hidden");
        videoRef.current?.pause();
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    }
  }, []);

  const handleVideoShowFullScreen = () => {
    if (videoRef.current) {
      videoRef.current.requestFullscreen()
        .then(() => {
          videoRef.current?.classList.remove("hidden");
	  videoRef.current?.play();
        });
    }
  };

  return (
    <div className={`${className} relative max-w-[323px] ${wide ? "h-[420px]" : "h-[462px]"} px-[30px] md:py-[20px] py-[15px] rounded-[30px] ${data.bg}`}>
      {/* <div className="absolute right-[30px] md:top-[40px] top-[30px] border-t-2 border-r-2 border-t-[#FF5B04] border-r-[#FF5B04] w-[14px] h-[14px]"></div> */}
      {mediaData.products[data.id].video.visibility && 
        <img onClick={handleVideoShowFullScreen} src={PlayIcon} alt="Play icon" className="absolute md:top-9 top-5 z-10 md:right-9 right-5 w-14 h-14 cursor-pointer" />
      }
      <div className="h-[68px] flex items-center text-[20px] gap-2">
        <img src={data.headerImage} />
        <p className="uppercase bg-clip-text font-bold leading-6" style={{textShadow: "0px 2.45px 2.45px #F9A323", background: "-webkit-linear-gradient(180deg, #F77D2B 0%, #F9A323 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>{data.title}</p>
      </div>
      <div className={`flex flex-col justify-between h-[83%] ${wide ? "w-[60%]" : "w-full"}`}>
        <p className="text-[16px] text-white mt-[17px] leading-[20px]">{data.text}</p>
        {data.customFooter ? (
          <div className="flex flex-col gap-[8px] z-10">
            <a href={data.googlePlayLink} target="_blank" rel="noreferrer">
              <img src={GooglePlayImage} className="w-[130px] cursor-pointer" />
            </a>
            <a href={data.appStoreLink?.length ? data.appStoreLink : undefined} target="_blank" rel="noreferrer">
              <img src={data.appStoreLink?.length ? AppStoreImage : AppStoreComingImage} className="w-[130px] cursor-pointer" />
            </a>
          </div>
        ) : (
          <a href={data.link} target="_blank" className="flex gap-4 items-center mb-2" rel="noreferrer">
            <p className="text-[16px] leading-4 text-orange-600 cursor-pointer font-semibold z-50">
              Visit<br className="lg:hidden block"/>{" "}website
            </p>
            <a href="/">
              <img src={ExertnalArrowSVG} alt="meet link" />
            </a>
          </a>
        )}
      </div>
      <img src={data.footerImage} className="absolute w-[40%] bottom-0 right-0 rounded-br-[30px]" />
      {mediaData.products[data.id].video.visibility &&
        <video controls ref={videoRef} width="100%" height="100%" className="hidden">
          <source src={mediaData.products[data.id].video.mp4} type="video/mp4" />
        </video>
      }
    </div>
  );
};

export default ProductCard;
