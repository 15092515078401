import { configureStore } from '@reduxjs/toolkit'
import themeReducer from "./feature/mode/changeMode";
import widthReducer from "./feature/mode/trackViewWidth";
import langReducer from "./feature/mode/changeLanguage";
import contactReducer from "./feature/mode/contactModal";
export const store = configureStore({
  reducer: {
    theme: themeReducer,
    size: widthReducer,
    lang: langReducer,
    contact: contactReducer
  },
})