import { useAnimate } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";

export default function MiddleArea() {
  const { t } = useTranslation();

  const scrollDown = () => {
    window.scrollTo({
      top: window.scrollY + window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <section className="section flex flex-col items-center">
      <div className="relative w-fit mx-auto sm:-mt-20">
        <img src={"/images/bg/hero-banner.webp"} alt="" className="h-auto sm:block hidden w-[46vw]" />
        <img src={"/images/bg/hero-banner-mobile.webp"} alt="" className="w-full h-auto sm:hidden ml-[25px] scale-125" />
        <a href="https://tymt.com" target="_blank" rel="noopener noreferrer">
          <img
            src={"/images/bg/dash.webp"}
            alt=""
            className="absolute w-[137px] sm:w-[26vw] top-[80px] -left-[0px] sm:top-[15vw] sm:-left-24 animate-GFG h-auto cursor-pointer"
          />
        </a>
        <a href="https://district53.io" target="_blank" rel="noopener noreferrer">
          <img
            src={"/images/bg/district.png"}
            alt=""
            className="absolute w-[130px] sm:w-[14.5vw] animate-GFGRev h-auto sm:bottom-[7vw] sm:-left-[4vw] bottom-[40vw] cursor-pointer"
          />
        </a>
        <a href="https://brightervpn.com" target="_blank" rel="noopener noreferrer">
          <img
            src={"/images/bg/brighter-vpn.png"}
            alt=""
            className="absolute w-[154px] sm:w-[19.8vw] animate-GFGRev h-auto sm:bottom-[7vw] sm:-right-[7vw] right-[2vw] bottom-[40vw] cursor-pointer"
          />
        </a>
        {/* Right Side */}
        <a href="https://solarcard.app" target="_blank" rel="noopener noreferrer">
          <img
            src={"/images/bg/credit-card.png"}
            alt=""
            className="absolute w-[154px] top-[50px] right-[0px] sm:right-0 sm:top-[15vw] animate-GFGRev hidden dark:block h-auto sm:w-[19.8vw] cursor-pointer"
          />
          <img
            src={"/images/bg/credit-card.png"}
            alt=""
            className="absolute w-[154px] top-[50px] right-[0px] sm:right-0 sm:top-[15vw] animate-GFGRev dark:hidden block h-auto sm:w-[19.8vw] cursor-pointer"
          />
        </a>
        {/* Left Side */}
        <a href="https://solarcard.app" target="_blank" rel="noopener noreferrer">
          <img
            src={"/images/bg/credit-card1-dark.webp"}
            alt=""
            className="absolute w-[146px] sm:w-[21.3vw] animate-GFG hidden dark:block h-auto sm:left-[12vw] sm:bottom-[0vw] left-[25vw] bottom-[10vw] cursor-pointer"
          />
          <img
            src={"/images/bg/credit-card1.webp"}
            alt=""
            className="absolute w-[146px] sm:w-[21.3vw] animate-GFG dark:hidden block h-auto sm:left-[12vw] sm:bottom-[0vw] left-[25vw] bottom-[10vw] cursor-pointer"
          />
        </a>
      </div>
      <button className="flex flex-col justify-center items-center gap-3 mt-10 text-center" onClick={scrollDown}>
        <span className="dark:text-white text-black">{t("scroll-down")}</span>
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9536 5.47928C15.2964 5.83918 15.2825 6.40887 14.9226 6.7517L8.62214 12.7533C8.2745 13.0844 7.7282 13.0844 7.3806 12.7533L1.08003 6.7517C0.720131 6.40887 0.706283 5.83918 1.04911 5.47928C1.39195 5.11937 1.96163 5.10553 2.32153 5.44835L8.00138 10.8586L13.6811 5.44835C14.0411 5.10553 14.6107 5.11937 14.9536 5.47928ZM14.9536 0.679278C15.2964 1.03918 15.2825 1.60887 14.9226 1.9517L8.62214 7.95331C8.2745 8.28439 7.7282 8.28439 7.3806 7.95331L1.08003 1.9517C0.720131 1.60887 0.706283 1.03918 1.04911 0.679278C1.39195 0.319374 1.96163 0.305526 2.32153 0.648354L8.00138 6.05866L13.6811 0.648354C14.0411 0.305526 14.6107 0.319374 14.9536 0.679278Z"
            fill="currentColor"
            className="dark:fill-white fill-black"
          />
        </svg>
      </button>
    </section>
  );
}
