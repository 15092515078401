import productCardHeaderImage1 from "../assets/icons/product-card-header-image-1.png";
import productCardHeaderImage2 from "../assets/icons/product-card-header-image-2.png";
import productCardHeaderImage3 from "../assets/icons/product-card-header-image-3.png";
import productCardHeaderImage4 from "../assets/icons/product-card-header-image-4.png";
import productCardHeaderImage5 from "../assets/icons/product-card-header-image-5.png";
import productCardFooterImage1 from "../assets/icons/product-card-footer-image-1.png";
import productCardFooterImage2 from "../assets/icons/product-card-footer-image-2.png";
import productCardFooterImage3 from "../assets/icons/product-card-footer-image-3.png";
import productCardFooterImage4 from "../assets/icons/product-card-footer-image-4.png";
import productCardFooterImage5 from "../assets/icons/product-card-footer-image-5.png";
import facebookSvg from "../assets/svg/facebook.svg";
import twitterSvg from "../assets/svg/twitter.svg";
import instagramSvg from "../assets/svg/instagram.svg";

import appleDownload from "../assets/Apple.png";
import googleDownload from "../assets/Google.png";

import GithubIcon from "../assets/logo/github.png";
import GithubLightIcon from "../assets/logo/github-light.png";
import MediumIcon from "../assets/logo/medium.png";
import MediumLightIcon from "../assets/logo/medium-light.png";
import DiscordIcon from "../assets/logo/discord";
import DiscordLightIcon from "../assets/logo/Discrod-light.png";
import TelegramIcon from "../assets/logo/telegram.png";
import TelegramLightIcon from "../assets/logo/telegram-light.png";
import TwitterIcon from "../assets/logo/Twitter";
import TwitterLightIcon from "../assets/logo/prime_twitter-light.png";
import InstagramIcon from "../assets/logo/instagram";
import FacebookIcon from "../assets/logo/facebook";

import GateIoLogo from "../assets/logo/GateIo.png";
import BitgetLogo from "../assets/logo/Bitget.png";
import SolarLogo from "../assets/logo/Solar.png";
import BinanceLogo from "../assets/logo/Binance.png";
import CoinmarketcapLogo from "../assets/logo/Coinmarketcap.png";

import ChangeNowLogo from "../assets/logo/changeNow.svg";
import ChangeNowLogoLight from "../assets/logo/changeNow-light.svg";
import XwinLogo from "../assets/logo/Xwin.svg";
import XwinLogoLight from "../assets/logo/Xwin-light.svg";

import GateIoLogoLight from "../assets/logo/GateIoLight.png";
import BitgetLogoLight from "../assets/logo/BitgetLight.png";
import SolarLogoLight from "../assets/logo/Solar-light.png";
import BinanceLogoLight from "../assets/logo/Binance-light.png";
import CoinmarketcapLogoLight from "../assets/logo/coinmarketcap-light.png";

export const shouldPreloadedImages = [
  "/images/icons/moon.svg",
  "/images/icons/sun.svg",
  "/images/icons/new-tag.svg",
  "/images/icons/new-tag-light.svg",
  "/images/icons/new-tag-light.svg",
  "/images/bg/footer-image.webp",
  "/images/bg/FAQ.webp",
  "/images/bg/FAQLight.webp",
  "/images/bg/JoinCommunity.webp",
  "/images/bg/JoinCommunityLight.webp",
  "/images/bg/JoinCommunityMobile.webp",
  "/images/bg/JoinCommunityMobileLight.webp",
  "/images/news/image.webp",
  "/images/news/image2.webp",
  "/images/news/image3.webp",
  "/images/news/image4.webp",
  "/images/news/image5.webp",
  "/images/shapes/Ellipse 1.svg",
  "/images/shapes/Ellipse 1-mobile.svg",
  "/images/shapes/Ellipse 2.svg",
  "/images/shapes/Ellipse 3.svg",
  "/images/shapes/header-hover.svg",
  "/images/shapes/inspired-hover.svg",
  "/images/shapes/join-community-hover.svg",
  "/images/shapes/solar-innovation-hover.svg",
  "/images/shapes/star.svg",
  "/images/shapes/star-light.svg",
  "/images/shapes/WhatsDifferent.webp",
  "/images/shapes/WhatsDifferentLight.webp",
  "/images/shapes/WhatsDifferentMobile.svg",
  "/images/shapes/WhatsDifferentMobileLight.svg",
  "/images/bg/ourimpact.webp",
  "/images/bg/numbers.webp",
  "/images/bg/numberOfproducts.webp",
  "/images/bg/numbers (1).webp",
  "/images/bg/numbers (2).webp",
  "/images/bg/OurImpactLines.webp",
  "/images/bg/OurImpactLinesLight.webp",
  "/images/bg/OurImpactLinesMobile.webp",
  "/images/bg/OurImpactLinesMobileLight.webp",
  "/images/icons/advance.webp",
  "/images/icons/advance-light.webp",
  "/images/icons/dpos.webp",
  "/images/icons/dpos-light.webp",
  "/images/icons/Expert.webp",
  "/images/icons/Expert-light.webp",
  "/images/icons/fast.webp",
  "/images/icons/fast-light.webp",
  "/images/icons/Solution.webp",
  "/images/icons/Solution-light.webp",
  "/images/icons/voting.webp",
  "/images/icons/voting-light.webp",
  "/images/bg/forCard.svg",
  "/images/bg/forCardMobile.svg",
  "/images/bg/Distic53.webp",
  "/images/bg/Distic53-light.webp",
  "/images/bg/Distic53Mobile.webp",
  "/images/bg/Distic53MobileLight.webp",
  "/images/bg/DocsBG.webp",
  "/images/bg/DocsBGLight.webp",
  "/images/bg/DocsLines.webp",
  "/images/bg/DocsLinesLight.webp",
  "/images/bg/DocsLinesMobile.webp",
  "/images/bg/DocsLinesMobileLight.webp",
  "/images/bg/mobileMenuBg.webp",
  "/images/bg/solar.webp",
  "/images/bg/solar-light.webp",
  "/images/bg/solarMobile.webp",
  "/images/bg/solarMobileLight.webp",
  "/images/bg/SolarCard.webp",
  "/images/bg/SolarCard-light.webp",
  "/images/bg/SolarCardMobile.webp",
  "/images/bg/SolarCardMobileLight.webp",
  "/images/bg/TeamBG.webp",
  "/images/bg/TeamBGLight.webp",
  "/images/bg/TeamBGMobile.webp",
  "/images/bg/TeamBGMobileLight.webp",
  "/images/bg/TeamLines.webp",
  "/images/bg/TeamLinesLight.webp",
  "/images/bg/TeamLinesMobile.webp",
  "/images/bg/TeamLinesMobileLight.webp",
  "/images/bg/Tymt.webp",
  "/images/bg/Tymt-light.webp",
  "/images/bg/TymtMobile.webp",
  "/images/bg/TymtMobileLight.webp",
  "/images/bg/WhatsDifferentContainerBg.webp",
  "/images/bg/WhatsDifferentContainerBgLight.webp",
  "/images/bg/WhatsDifferentContainerBgMobile.webp",
  "/images/shapes/stake.svg",
  "/images/shapes/stake-light.svg",
  "/images/shapes/stake-mobile.svg",
  "/images/bg/credit-card.png",
  "/images/bg/credit-card-dark.webp",
  "/images/bg/credit-card1.webp",
  "/images/bg/credit-card1-dark.webp",
  "/images/bg/district.webp",
  "/images/bg/dash.webp",
  "/images/bg/Wallet balance Solar.webp",
  "/images/bg/hero-banner.webp",
  "/images/bg/hero-banner-mobile.webp",
  "/images/icons/arrow-up-right.svg",
  "/images/icons/arrow-up-right-orange.svg",
];

export const HeroData = {
  bottom: {
    icons: [SolarLogo, BinanceLogo, CoinmarketcapLogo, BitgetLogo, GateIoLogo, ChangeNowLogo, XwinLogo],
    iconsLight: [SolarLogoLight, BinanceLogoLight, CoinmarketcapLogoLight, BitgetLogoLight, GateIoLogoLight, ChangeNowLogoLight, XwinLogoLight],
  },
};

export const StakeData = [
  {
    name: "tymt-name",
    desc: "tymt-desc",
    href: "https://tymt.com/",
    imageSrc: "/images/bg/Tymt.webp",
    imageLightSrc: "/images/bg/Tymt-light.webp",
    imageMobileSrc: "/images/bg/TymtMobile.webp",
    imageMobileLightSrc: "/images/bg/TymtMobileLight.webp",
    bulletPoints: ["tymt-bulletPoints-1", "tymt-bulletPoints-2", "tymt-bulletPoints-3"],
  },
  {
    name: "solar-mobile-name",
    imageSrc: "/images/bg/solar.webp",
    imageLightSrc: "/images/bg/solar-light.webp",
    imageMobileSrc: "/images/bg/solarMobile.webp",
    imageMobileLightSrc: "/images/bg/solarMobileLight.webp",
    desc: "solar-mobile-desc",
    bulletPoints: ["solar-mobile-bulletPoints-1", "solar-mobile-bulletPoints-2", "solar-mobile-bulletPoints-3", "solar-mobile-bulletPoints-4"],
    CustomFooter: (
      <div className="w-full flex justify-center md:justify-start gap-6">
        <a href="https://play.google.com/store/apps/details?id=com.solarwallet&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
          <button className="border border-white border-opacity-0 hover:border-opacity-50 hover:-translate-y-1 transition-all duration-200 ease-in-out rounded-lg overflow-hidden">
            <img src={googleDownload} alt="" />
          </button>
        </a>
        <a
          // href="/home"
          // target="_blank"
          rel="noopener noreferrer"
        >
          <button className="border border-white border-opacity-0 hover:border-opacity-50 hover:-translate-y-1 transition-all duration-200 ease-in-out rounded-lg overflow-hidden">
            <img src={appleDownload} alt="" />
          </button>
        </a>
      </div>
    ),
    isBig: true,
  },
  {
    name: "solar-card-name",
    imageSrc: "/images/bg/SolarCard.png",
    imageLightSrc: "/images/bg/SolarCard-light.png",
    imageMobileSrc: "/images/bg/SolarCardMobile.png",
    imageMobileLightSrc: "/images/bg/SolarCardMobileLight.png",
    desc: "solar-card-desc",
    bulletPoints: ["solar-card-bulletPoints-1", "solar-card-bulletPoints-2", "solar-card-bulletPoints-3", "solar-card-bulletPoints-4"],
    isBig: true,
    href: "https://solarcard.app/",
  },
  {
    name: "district-name",
    imageSrc: "/images/bg/Distic53.webp",
    imageLightSrc: "/images/bg/Distic53-light.webp",
    imageMobileSrc: "/images/bg/Distic53Mobile.webp",
    imageMobileLightSrc: "/images/bg/Distic53MobileLight.webp",
    desc: "district-desc",
    bulletPoints: ["district-bulletPoints-1", "district-bulletPoints-2", "district-bulletPoints-3"],
    href: "https://district53.io/",
  },
  {
    name: "brighter-vpn-name",
    imageSrc: "/images/bg/brighter-vpn-bg.png",
    imageLightSrc: "/images/bg/brighter-vpn-bg-light.png",
    imageMobileSrc: "/images/bg/brighter-vpn-bg-mobile.png",
    imageMobileLightSrc: "/images/bg/brighter-vpn-bg-mobile-light.png",
    desc: "brighter-vpn-desc",
    bulletPoints: ["brighter-vpn-bulletPoints-1", "brighter-vpn-bulletPoints-2", "brighter-vpn-bulletPoints-3"],
    href: "https://brightervpn.com/",
    isFull: true,
  },
];

export const DifferenceData = {
  differences: [
    {
      title: "what-difference-sub1-title",
      desc: "what-difference-sub1-desc",
      imgSrcDark: "/images/icons/dpos.webp",
      imgSrcLight: "/images/icons/dpos-light.webp",
    },
    {
      title: "what-difference-sub2-title",
      desc: "what-difference-sub2-desc",
      imgSrcDark: "/images/icons/fast.webp",
      imgSrcLight: "/images/icons/fast-light.webp",
    },
    {
      title: "what-difference-sub3-title",
      desc: "what-difference-sub3-desc",
      imgSrcDark: "/images/icons/voting.webp",
      imgSrcLight: "/images/icons/voting-light.webp",
    },
    {
      title: "what-difference-sub4-title",
      desc: "what-difference-sub4-desc",
      imgSrcDark: "/images/icons/advance.webp",
      imgSrcLight: "/images/icons/advance-light.webp",
    },
  ],
};

export const FooterData = {
  copyrightPages: [
    { title: "footer-copy-page-1", href: "/terms" },
    { title: "footer-copy-page-2", href: "/privacy" },
  ],
  socials: [
    {
      href: " https://x.com/brighter_solar?t=skllnihWTr_TwmnhXQfmhg&s=09",
      Icon: TwitterIcon,
    },
    {
      href: "https://discord.gg/Ss7snQTD",
      Icon: DiscordIcon,
    },
    {
      href: "https://www.instagram.com/solar_enterprises_sxp?igsh=MW1idWQ5b3NldWZ4bg==",
      Icon: InstagramIcon,
    },
    {
      href: "https://www.facebook.com/SolarEnterprisesOfficial",
      Icon: FacebookIcon,
    },
  ],
  AboutUs: [
    { title: "footer-github", href: "https://github.com/solarenterprises" },
    { title: "blog", href: `${process.env.REACT_APP_API_URL_PREFIX}/blog` },
  ],
  Products: [
    { title: "footer-product-1", href: "https://solar.org/wallets" },
    { title: "footer-product-2", href: "https://tymt.com/" },
    { title: "footer-product-3", href: "https://district53.io/" },
    { title: "footer-product-4", href: "https://solarcard.app" },
  ],
  /* Blog: [
    { title: "footer-blog-1", href: `${process.env.REACT_APP_API_URL_PREFIX}/blog" },
    // { title: "footer-blog-2", href: "" },
  ], */
};

export const DocsData = {
  points: [
    {
      title: "docs-points-1-title",
      desc: "docs-points-1-desc",
      img: "/images/icons/Solution.webp",
      imgLight: "/images/icons/Solution-light.webp",
    },
    {
      title: "docs-points-2-title",
      desc: "docs-points-2-desc",
      img: "/images/icons/Expert.webp",
      imgLight: "/images/icons/Expert-light.webp",
    },
  ],
};

export const OurImpactData = {
  btnHref: "https://solar.org/",
  cards: [
    {
      icon: "/images/icons/Solution.webp",
      iconLight: "/images/icons/Solution-light.webp",
      title: "our-impact-card-1-title",
      desc: "our-impact-card-1-desc",
    },
    {
      icon: "/images/icons/Expert.webp",
      iconLight: "/images/icons/Expert-light.webp",
      title: "our-impact-card-2-title",
      desc: "our-impact-card-2-desc",
    },
  ],
  impacts: [
    {
      icon: "/images/bg/ourimpact.webp",
      heading: "impacts-1",
    },
    {
      icon: "/images/bg/numbers (2).webp",
      heading: "impacts-2",
    },
    {
      icon: "/images/bg/numbers (1).webp",
      heading: "impacts-3",
    },
    {
      icon: "/images/bg/numberOfproducts.webp",
      heading: "impacts-4",
    },
  ],
};

export const LatestNewsData = {
  title: 'Latest <span style="color: rgb(237 92 21)">news</span>',
  news: [
    {
      title: "news-sub-1-title",
      date: "news-sub-1-date",
      img: "/images/news/image.webp",
    },
    {
      title: "news-sub-2-title",
      date: "news-sub-2-date",
      img: "/images/news/image2.webp",
    },
    {
      title: "news-sub-3-title",
      date: "news-sub-3-date",
      img: "/images/news/image3.webp",
    },
    {
      title: "news-sub-4-title",
      date: "news-sub-4-date",
      img: "/images/news/image4.webp",
    },
    {
      title: "news-sub-5-title",
      date: "news-sub-5-date",
      img: "/images/news/image5.webp",
    },
  ],
};

export const JoinCommunityData = {
  communities: [
    {
      name: "join-community-1-name",
      desc: "join-community-1-desc",
      href: "https://t.me/SolarEnterprises",
      icon: TelegramIcon,
      iconLight: TelegramLightIcon,
    },
    {
      name: "join-community-2-name",
      desc: "join-community-2-desc",
      href: `${process.env.REACT_APP_API_URL_PREFIX}/blog `,
      icon: MediumIcon,
      iconLight: MediumLightIcon,
    },
    {
      name: "join-community-3-name",
      desc: "join-community-3-desc",
      href: "https://github.com/solarenterprises",
      icon: GithubIcon,
      iconLight: GithubLightIcon,
    },
  ],
};

export const TeamData = {
  members: [
    {
      type: "team-management",
      members: [
        {
          name: "Nayiem Willems",
          job: "Chief Executive Officer",
          icon: "/images/members/Nayiem.webp",
          href: "https://x.com/WillemsTRA",
        },
        // {
        //   name: "Armand Habets",
        //   job: "Chief Operating Officer",
        //   icon: "/images/members/Armand.webp",
        //   href: "https://www.linkedin.com/in/armandhabets",
        // },
        // {
        //   name: "Agne",
        //   job: "Legal, Compliance & KYC Officer",
        //   icon: "/images/members/Agne.webp",
        //   href: "https://www.linkedin.com/in/armandhabets",
        // },
        {
          name: "Jacek Studzinski",
          job: "Chief Marketing Officer",
          icon: "/images/members/Jacek.webp",
          href: "https://linkedin.com/in/jacekstudzinski",
        },
        // {
        //   name: "Jing Zhao",
        //   job: "Chief Technology Officer",
        //   icon: "/images/members/Jing.webp",
        //   href: "https://twitter.com/zhjing0105",
        // }
      ],
    },
    {
      type: "team-design",
      members: [
        {
          name: "Taras Oliinyk",
          job: "Lead UI/UX designer",
          icon: "/images/members/taras.webp",
          href: "https://www.linkedin.com/in/taras-oliinyk/",
        },
        {
          name: "Iryna Kalinku",
          job: "UI/UX designer",
          icon: "/images/members/iryna.webp",
          href: "https://www.linkedin.com/in/kalinku-design/",
        },
      ],
    },
    {
      type: "team-development",
      members: [
        {
          name: "Sami Mattila",
          job: "Lead Game Developer",
          icon: "/images/members/sami.png",
          href: "https://www.linkedin.com/in/sami-mattila/",
        },
        {
          name: "Bruce Hamada",
          job: "Full-Stack Developer",
          icon: "/images/members/bruce.webp",
          href: "https://www.linkedin.com/in/bruce-hamada-12a19a2b4/",
        },
        {
          name: "Farhan Yusof",
          job: "Full-Stack Developer",
          icon: "/images/members/farhan.webp",
          href: "https://www.linkedin.com/in/farhan-yusof-0baa592b4/",
        },
      ],
    },
  ],
};

export const pdfFile = "/Solar Enterprises - Privacy Policy.pdf";

export const ProductData = [
  {
    id: "brightervpn",
    headerImage: productCardHeaderImage1,
    footerImage: productCardFooterImage1,
    text: "Your all-in-one solution for privacy and security in the crypto space. Keep your digital life anonymous and enjoy limitless access to decentralised platforms with a VPN built for the blockchain era.",
    link: "https://brightervpn.com",
    customFooter: false,
    bg: "product-card-bg-1",
  },
  {
    id: "solarcard",
    headerImage: productCardHeaderImage2,
    footerImage: productCardFooterImage2,
    text: "Simplify your crypto lifestyle with Solar Card, the crypto-to-fiat debit card that makes transactions in both the digital and traditional financial worlds seamless. Be among the first to get access to this revolutionary card—apply today!",
    link: "https://solarcard.app",
    customFooter: false,
    bg: "product-card-bg-2",
  },
  {
    id: "tymt",
    headerImage: productCardHeaderImage3,
    footerImage: productCardFooterImage3,
    text: "The Web3 gaming launcher that puts you at the forefront of blockchain-powered gaming. Dive into the decentralised gaming revolution with instant access to groundbreaking titles and true asset ownership.",
    link: "https://tymt.com",
    customFooter: false,
    bg: "product-card-bg-3",
  },
  {
    id: "district53",
    headerImage: productCardHeaderImage4,
    footerImage: productCardFooterImage4,
    text: "Enter District 53, our metaverse game where you can explore, build, and thrive in a decentralised, immersive world. Experience a new way to play—powered by the speed and sustainability of the Solar Blockchain.",
    link: "https://district53.io",
    customFooter: false,
    bg: "product-card-bg-4",
  },
  {
    id: "mobileWallet",
    headerImage: productCardHeaderImage5,
    footerImage: productCardFooterImage5,
    text: "Easily manage your digital assets from anywhere. With a simple design and strong security, the Solar Mobile Wallet lets you quickly buy, sell, trade, and send a wide range of cryptocurrencies, including SXP, all in one place. Take control of your crypto journey with financial freedom right in your hand.",
    link: "https://",
    title: "Solar Mobile Wallet",
    customFooter: true,
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.solarwallet&pcampaignid=web_share",
    appStoreLink: "",
    bg: "product-card-bg-5",
  },
];

export const SocialLinkData = [
  {
    image: facebookSvg,
    link: "https://www.facebook.com/SolarEnterprisesOfficial",
  },
  {
    image: twitterSvg,
    link: "https://x.com/brighter_solar",
  },
  {
    image: instagramSvg,
    link: "https://www.instagram.com/solar_enterprises_sxp/",
  },
];

export const DocLinkData = {
  termsOfService: "",
  privacyPolicy: "",
};
