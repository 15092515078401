import clsx from "clsx";
import React from "react";

import ArrowIcon from "../../assets/icons/arrow";
import logo from "../../assets/logo.png";
import logoLight from "../../assets/logo-light.png";

const index = ({ animate, t, title, content, image, url, date}) => {
  return (
    <a
      href={url}
      target="_blank"
      key={index}
    >
      <div
        className={clsx(
          "relative flex flex-col w-[164px] md:w-60 rounded-2xl group cursor-pointer",
          animate
        )}>
        {/* arrow */}
        <div className="absolute top-0 right-0 flex justify-end items-start h-[28px] w-[28px] md:h-14 md:w-14 rounded-2xl">
          <div className="dark:bg-[#FFFFFF08] bg-[#FFFFFF] rounded-full h-[28px] w-[28px] md:h-10 md:w-10 justify-center items-center flex dark:group-hover:bg-white group-hover:bg-theme-orange">
            <ArrowIcon className="h-[12px] w-[12px] md:w-[24px] md:h-[24px] dark:block hidden fill-theme-orange" />
            <ArrowIcon className="h-[12px] w-[12px] md:w-[24px] md:h-[24px] dark:hidden fill-theme-orange group-hover:fill-theme-white" />
          </div>
        </div>
        {/* main card component  */}
        <div className=" flex flex-col">
          <div className="dark:bg-[#27272A] bg-[#FFFFFF] rounded-2xl relative rounded-br-none rounded-bl-none h-[40px] md:h-14 flex items-center p-2 md:pl-5  w-[75%]">
            <img src={logo} alt="" className="w-[78px] md:w-24 dark:block hidden" />
            <img src={logoLight} alt="" className="w-[78px] md:w-24 dark:hidden" />
            <div
              className="curved-shape absolute bottom-[-1px] right-[-30px] dark:bg-[#27272A] bg-[#FFFFFF]"
              style={{
                width: "30px",
                height: "30px",
                clipPath: 'path("M 0,0 C 0,30 15,30 30,30 L 0,30 Z")',
              }}>
            </div>
          </div>
          <div className="dark:bg-[#27272A] bg-[#FFFFFF] rounded-2xl rounded-tl-none p-2 flex flex-col gap-2">
            <span className="line-clamp-[2] font-bold text-[14px] leading-[18.2px] dark:text-white text-black truncate md:text-overflow-clip md:whitespace-normal">
              {t(title)}
            </span>
            <div className="line-clamp-[4] text-xs text-opacity-70 dark:text-white/70 text-black md:text-overflow-clip md:whitespace-normal">
              {t(content)}{" "}
            </div>
            <img src={image} alt="" className="w-full h-24 hidden md:block" />
            <img src={image} alt="" className="w-full h-[80px] md:h-24 md:hidden" />
            {/* <img src={'/images/bg/forCard.svg'} alt="" className="w-full h-24 hidden md:block" />
            <img src={'/images/bg/forCardMobile.svg'} alt="" className="w-full h-[80px] md:h-24 md:hidden" /> */}
          </div>
        </div>
      </div>
    </a>
  );
};

export default index;
