import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  language: null,
}

export const changeLN = createSlice({
    name: 'language',
    initialState,
    reducers: {
      setLN: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.language = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setLN } = changeLN.actions

export default changeLN.reducer