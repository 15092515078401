export default function NextIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6.98578 3.36575C7.22571 3.1372 7.60551 3.14643 7.83406 3.38636L11.8351 7.58674C12.0559 7.81848 12.0559 8.18268 11.8351 8.41444L7.83406 12.6148C7.60551 12.8547 7.22571 12.864 6.98578 12.6354C6.74584 12.4068 6.73661 12.0271 6.96516 11.7871L10.572 8.0006L6.96516 4.21403C6.73661 3.97409 6.74584 3.59431 6.98578 3.36575ZM3.78578 3.36575C4.02571 3.1372 4.40551 3.14643 4.63406 3.38636L8.63513 7.58674C8.85585 7.81848 8.85585 8.18268 8.63513 8.41444L4.63406 12.6148C4.40551 12.8547 4.02571 12.864 3.78578 12.6354C3.54584 12.4068 3.53661 12.0271 3.76516 11.7871L7.37203 8.0006L3.76516 4.21403C3.53661 3.97409 3.54584 3.59431 3.78578 3.36575Z" />
    </svg>
  );
}
