const Paperless = () => {
  return (
    <div className="w-full px-[24px] mt-[120px] flex flex-col justify-center items-center">
      <div className="max-w-[1080px]">
        <p className="text-center text-[15px] md:text-[20px] text-orange-600">Why We’re Going Paperless:<br className="md:hidden block"/> Pioneering a Sustainable Future</p>
        <p className="text-center text-[24px] lg:text-[40px] text-white mt-[40px] leading-[28px] lg:leading-[50px] font-bold">
          At Solar Enterprises, sustainability is at our core. That’s why we have chosen to go completely paperless at
          Binance Blockchain Week. No wasteful flyers, no unnecessary swag—just a focus on digital innovation that minimises our impact on the environment. By
          embracing eco-friendly practices, we are leading the way in reducing blockchain’s carbon footprint.
        </p>
      </div>
    </div>
  );
};

export default Paperless;
