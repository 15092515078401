import whiteSolarEnterprisesImage from "../../../assets/icons/solar-enterprises.png";
import subscribeImage from "../../../assets/icons/subscribe.png";
import rightArrowSvg from "../../../assets/svg/right-arrow.svg";
import backgroundImage from "../../../assets/backgrounds/visit-bg.png";

const Build = () => {
  return (
    <div className="w-full px-[24px] mt-[120px] flex flex-col justify-center items-center">
      <div className="bg-cover bg-center w-full max-w-[1481px] md:py-[60px] py-[30px] px-[30px] flex flex-col justify-center items-center md:rounded-[30px] rounded-2xl bg-[#141418]" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <img src={whiteSolarEnterprisesImage} className="w-[180px] h-[46px]" />
        <div className="max-w-[734px] mt-[40px]">
          <p className="text-white text-[20px] lg:text-[40px] font-bold text-center lg:leading-[46px] leading-[25.5px]">
            Let’s build a brighter, smarter, and greener future — together!
          </p>
        </div>
        <p className="text-white text-[16px] mt-[20px] text-center md:px-0 px-8">Leave your email to receive updates on Solar</p>
        <div className="flex flex-wrap gap-[8px] mt-[40px] w-full justify-center">
          {/* <input className="border border-[#07011219] bg-white text-black rounded-full px-[16px] py-[13px] w-full md:max-w-[320px]" placeholder="name@xyz.com" /> */}
          <a href="https://solarenterprises.com/blog/#/portal/signup" className="relative overflow-hidden bg-black w-full md:max-w-[280px] rounded-[30px] cursor-pointer flex flex-row justify-center items-center gap-2 px-[16px] py-[13px]">
            <img src={subscribeImage} className="absolute top-0 left-0 w-[46px] h-[57px]" />
            <p className="text-[16px] text-orange-600 font-bold">Subscribe</p>
            <img src={rightArrowSvg} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Build;
