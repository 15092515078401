const routes = [
  {
    title: "menu-home",
    href: "/",
    children: [],
    index: true,
  },
  {
    title: "menu-products",
    href: "/#products",
    children: [],
    index: true,
  },
  {
    title: "menu-team",
    href: "/#team",
    children: [],
    index: true,
  },
  {
    title: "menu-docs",
    href: "/docs",
    children: [],
    index: true,
  },
  {
    title: "menu-news",
    href: "/#news",
    children: [],
    index: true,
  },
  {
    title: "menu-news-details",
    href: "/blogs/:slug",
    children: [],
    index: true,
  },
  {
    title: "menu-news-blogdetails",
    href: "/blogs/blogdetails",
    children: [],
    index: true,
  }
];

export default routes;
