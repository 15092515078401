export default function FilterBottom({color, className}) {
    return (
        <svg width="344" height="80" className={className} viewBox="0 0 344 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="344" height="80" transform="matrix(1 0 0 -1 0 80)" fill="url(#paint0_linear_923_6527)"/>
            <defs>
            <linearGradient id="paint0_linear_923_6527" x1="172" y1="0" x2="172" y2="80" gradientUnits="userSpaceOnUse">
            <stop stopColor={color}/>
            <stop offset="1" stopColor={color} stopOpacity="0"/>
            </linearGradient>
            </defs>
        </svg>
    );
}
  