import React from 'react'

function Term() {
    return (
        <div className='containerAlt px-[20px] md:px-5 lg:w-full sb:px-10 xl:px-36 justify-between pt-6 md:pb-[26px] gap-4 privacy'>
            <div className='text-center font-bold text-[3em] lg:text-[4em]'>
                <h1>Comming Soon</h1>
            </div>
        </div>
    )
}

export default Term