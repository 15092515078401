import React from 'react'

function Privacy() {
    return (
        <div class="solar-container">
            <h1>Solar Enterprises</h1>
            <h1>DATA PRIVACY POLICY</h1>
            <h3>GENERAL INFORMATION</h3>
            <p><span className='italic'>Solar Enterprises</span> is committed to protecting your personal data and respects your privacy on all its websites and domains. The Privacy Policy (the <span>Policy</span>) has been prepared in accordance with the General Data Protection Regulation (Regulation (EU) 2016/679), Regulation (EU) 2018/1725, and other legislation governing the legal protection of personal data.</p>
            <p>Like most website operators, we collect data about visitors to our Website at <a href="http://www.solarenterprises.com">www.solarenterprises.com</a> (the <span>Website</span>) and our mobile application Solar Mobile Wallet (the <span>Application</span>). This helps us understand how useful the Website and the Application are and how to improve them. We also process personal data (i.e. by sending newsletters and analysing interest(s) in our services). Our data collection practices are governed by this Data Privacy Policy (the Policy), which explains to you what data we collect, how we use such data, who has access to that data, and what rights thereto you have.</p>
            <p>This Policy may at any time be amended if the scope of processing or our processing practices change. You are required to acquaint yourself with the Policy every time you update business relations with us, contact us, and keep being updated. Please note that if you become our customer (the Customer), we inform you about the significant Policy changes personally.</p>
            <p>The legal basis to collect and process this personal data is your consent, to collect and process your personal data is our legitimate interest to respond to inquiries about us and our Services.</p>

            <h2>1. INFORMATION WE COLLECT AND HOW WE USE IT</h2>
            <p>In this Policy, the term “personal data” is defined as any information that directly or indirectly allows identify the Data Subject, and primarily relates to an identified or identifiable natural person. Other terms are according to the applicable legislation mentioned hereinabove.</p>
            <p>This Policy applies when a Data Subject:</p>
            <ul>
                <li>Uses, has used or has expressed an intention to use or interest in using our services (the Services);</li>
                <li>Visits our website;</li>
                <li>Is a principal or beneficial owner, shareholder, member of the board of directors or other collegiate body of the business Customer;</li>
                <li>Is a representative of the Customer (whether corporate or private);</li>
                <li>Is indirectly related to the Services (e.g. is the Customer’s spouse, collateral provider, the Customer’s data was provided by the Customer, etc.);</li>
                <li>Is an agent of any third party who is engaged in a legal relationship with us (for example, an agent of a company that provides services or sells goods to us);</li>
                <li>Has provided his Personal Data or we have received Personal Data for other legitimate reasons, not mentioned at the beginning (for example, Personal Data of third parties in documents submitted to us by the Customer, his representative, as well as regarding the processing of the list of shareholders, etc.).</li>
            </ul>
            <p>Our main purposes and methods of usage and processing your personal data and any related information through us are to:</p>
            <ul>
                <li>Provide, operate, and maintain our Website;</li>
                <li>Improve, personalise, and expand our Website;</li>
                <li>Understand and analyse how you use our Website;</li>
                <li>Develop new products, services, features, and functionality;</li>
                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Website, and for marketing and promotional purposes;</li>
                <li>Send you emails and/or newsletters;</li>
                <li>Find and prevent any fraudulent activity;</li>
                <li>Protect your rights;</li>
                <li>Otherwise ensure implementation of compliance principles and requirements.</li>
            </ul>
            <h3>1.1. Newsletters</h3>
            <p>When you register at our Website to receive our newsletter, we collect your name, country of residence, and e-mail address. The additional details may also be collected upon a prior written notifying and obtaining your consent. We process this data for the purposes of sending you correspondence with information about us, our services, related news and other information that we consider may be of interest to you. We also use the collected data to analyse interest in our services based on demographic and geographical attributes.</p>
            <p>Please note that by applying to us, creating an account, RSVPing attending an event, or otherwise using our services in any manner whatsoever, you agree to be bound by this Policy. Your continued use of our services after the date any such changes become effective constitutes your acceptance of the new version of this Policy. You should regularly check this page for updates. If you do not accept and agree to be bound by this Policy, please do not use our services, or contact us personally for further explanation.</p>
            <h3>1.2. Inquiries and answers</h3>
            <p>When you contact us with an inquiry via the contacts available at our Website we will process your contact details and content of your message to respond to your inquiry.</p>
            <h3>1.3. Cookies and Log Files</h3>
            <p>When you browse our Website, we collect and process certain personal data via use of cookies. Please read our description of cookies below.</p>
            <h4 className='italic'><u>Cookies and Web Beacons</u></h4>
            <p>Like any other website, we use “cookies”. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited.</p>

            <p>The information is used to optimise the users' experience by customising our web page content based on visitors' browser type and/or other information.</p>
            <p>You can choose to disable cookies through your individual browser options. More detailed information about cookie management with specific web browsers can be found on the browsers' of the respective websites.</p>
            <p>We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below.</p>
            <p>The cookies that are categorised as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site.</p>
            <p>We also use third-party cookies that help us analyse how you use this Website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent.</p>
            <p>You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.</p>
            <h4>Necessary</h4>
            <p>Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data. These cookies are always enabled and cannot be turned off.</p>
            <p>Required cookies are key to the operation of our site. They enable you to navigate our website and its subdomains and use its features. Without these cookies, the Website and its subdomains cannot function properly. Please be aware that these don’t collect information that can identify you.</p>
            <h4>Analytics</h4>
            <p>These cookies help to improve our Website and its subdomains by collecting information about your interactions with it. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc. They show us which pages are most frequently</p>

            <p>visited and help us to record the difficulties that you may have while using our website and its subdomains.</p>
            <h4>Performance / Functional</h4>
            <p>Performance cookies are used to understand and analyse the key performance indexes of the Website which helps in delivering a better user experience for the visitors. Functional cookies help perform certain functionalities like sharing the content of the Website on social media platforms, collecting feedback, and other third-party features.</p>
            <p>These cookies are used to enhance the performance and functionality of our website and its subdomains but are non-essential. However, without these cookies, certain functionalities like videos may become unavailable, or you would be required to enter your login details every time you visit our website and its subdomains as we would not be able to remember that you had logged in previously.</p>
            <h4>Advertisement</h4>
            <p>Advertisement cookies are used to provide visitors with customised advertisements based on the pages you visited previously and to analyse the effectiveness of the ad campaigns.</p>
            <p>These cookies collect information about your online activities over time, in order to personalize advertisements. They also allow us to limit the number of times you see our ads across your devices and measure the effectiveness of our marketing campaigns.</p>
            <h4>Uncategorized</h4>
            <p>Other uncategorized cookies are those that are being analysed and have not been classified into a category as yet.</p>
            <h4 className='italic'><u>Log Files</u></h4>
            <p>We follow a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and are a part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is to analyse trends, administer the site, track users' movement on the Website, and gather demographic information.</p>

            <h3>1.4. Enhanced Due Diligence</h3>
            <p>In cases specified in our AML policy and procedures, we shall perform the enhanced due diligence on our Customer, where additional information is obtained by us, such as, but not limited to the following:</p>
            <ul>
                <li>Information about the Customer and the Beneficiary;</li>
                <li>Information about the proposed nature of the business relationship;</li>
                <li>Information about the sources of funds and the sources of wealth of the Customer and the Beneficiary;</li>
                <li>Information about the causes of any planned or executed monetary operations or transactions.</li>
            </ul>
            <h3>1.5. Mobile Application, Web Application, and Cross-Platform Desktop Application</h3>
            <p>a) Information while signing up:</p>
            <ul>
                <li>Personal details: your name, birth details (date, place, name, mother name, etc.);</li>
                <li>Contact details: your registered and/or correspondent address, email address and phone number, etc.;</li>
                <li>Identification document: type of document, issue date, document number and issuing country, etc.;</li>
                <li>Photograph, etc.;</li>
            </ul>
            <p>b) Information from mobile application usage:</p>
            <ul>
                <li>Details about payments to and from your account at us.</li>
            </ul>
            <p>c) Information about the mobile device:</p>
            <ul>
                <li>Technical information: device type, device name, IP address, device ID, and operating system, so we can analyse how our app works and fix any problems;</li>
            </ul>
            <p>d) Generally collected information collected, regardless of the place thereof:</p>
            <ol>
                <li>Personal identity and contact details – name, surname, personal identification number, date of birth, details of identity document, registration address, telephone number, e-mail address, address of residence or address for correspondence, country of residence.</li>
                <li>Identification data – data of a person’s identity document, photo, IP address, internet login data, and other browsing information, including data on when and where our Website was accessed, as well as the taxpayer’s identification number.</li>
                <li>Financial data – data on the current/former workplace, economic-commercial activities carried out (for example, farmer, self-employment, etc.), data on accounts, assets held, transactions, loans, income, including projected income and their stability, expenses, liabilities, data on financial experience, credit history, and creditworthiness, as well as data on the prohibition to conclude consumer credit agreements.</li>
                <li>Data about the transactions of the Data Subject with us and other agreements concluded – depending on the Services provided to the Data Subject by us, the following data are processed: bank/VASP account/wallet number, deposits, payment orders and/or other payment transactions, payee details, payment instruments and the actions taken using them, deposits, withdrawals, etc.</li>
                <li>Data relating to the reliability and performance assessment of the Data Subject – data on financial transactions, damage caused to us, data necessary for us to apply the necessary measures in the field of prevention of money laundering and terrorist financing and to ensure the enforcement of international sanctions, including to determine the purpose of the business relationship with the Data Subject and the fact whether the Data Subject is a politically exposed person as well as the source of origin of the assets, data on the parties to the transactions of the Data Subject, business activities, beneficial owners.</li>
                <li>Data obtained and/or created in compliance with the requirements of legal acts – data which we are required to provide to public authorities such as tax administrations, courts, law enforcement authorities, notaries, bailiffs, other executive authorities, including data on income, financial liabilities, owned property, uncovered debts, data on the origin of funds, the country of residence for tax purposes, the status of the taxpayer and data on payment transactions and their execution.</li>
                <li>Data collected by means of communication and other technical means – data which are provided in e-mails, photographs, video and/or audio recordings; data collected when the Data Subject visits our customer service departments or communicates with us, data related to the Data Subject’s visits to our websites or collected through our system(s).</li>
                <li>Data on behavioural habits, priorities and satisfaction with the Services – data on the activity using the Services, the Services provided to the Data Subject, personal settings in our system(s), feedback from the Data Subject on the Services, whether the Data Subject is satisfied with the Services.</li>
                <li>Special categories of Personal Data – data related to the health of the Data Subject, biometric data (when performing remote identification, during which a unique identification of the person is confirmed, such as a facial image). We shall use biometric data for remote identification of the Data Subject only when the Data Subject has expressly given his consent to the use of such an identification method by a service provider of this kind engaged by us. In certain cases, in order to provide the Services, we are required to process special categories of Personal Data.</li>
                <li>Family data – information about the family of the Data Subject, his marital status, number of dependents, heirs, and other related persons. However, please note that Solar we do not knowingly collect any personally identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</li>
                <li>Data related to profession – data on education and professional activity.</li>
                <li>Demographic data – country of residence, date of birth and nationality.</li>
            </ol>
            <h2>2. WHO MAY SEE YOUR DATA</h2>
            <p>We put our best efforts into keeping your data safe and always require the highest level of security and confidentiality from our employees, partners, and group companies, which are subjects which we may share your data with.</p>
            <p>We may share your personal data with our trusted service providers when they provide services to us or to you on behalf of us and under our instructions. This may include, for example, providers of IT solutions and cloud services. We will control and shall remain responsible for the use of your personal data at all times.</p>
            <p>Your personal data may be disclosed to public authorities if we are required to disclose personal data by law or to comply with a lawful request of authorities.</p>
            <p>If we are ever involved in a corporate transaction, for example if our shares are bought by third parties, we may transfer your personal data to investors or potential investors, as well as other recipients involved in the relevant transaction.</p>

            <h2>3. HOW LONG WE KEEP YOUR DATA</h2>
            <p>Your personal data collected when you register an account with us and/or subscribe to any type of our newsletter, will be processed until you withdraw your consent (unsubscribe).</p>
            <p>Personal data related to your inquiries will be stored for up to 2 years.</p>
            <p>In order to properly provide the Services, your personal data is stored for a longer period of time in accordance with the requirements of applicable legal acts as well as if personal data needs to be stored to protect our legitimate interests or those of any third parties, e.g., in the event of a legal dispute. The term of storage of your personal data may be additionally extended for up to 2 years upon a reasoned instruction of a competent authority.</p>
            <p>Our most common time limits for the storage of your personal data are listed below:</p>
            <ul>
                <li>Personal data necessary for the provision of our Services to you/your represented legal entity and fulfilment of our commitments and obligations, arising out of our terms of use for the provision of the Services, we will keep for all the period of business relationships with you and for the below indicated periods from their termination;</li>
                <li>Copies of the Customer/Client identity verification documents, beneficial ownership identification data, direct video transmission (live video broadcast records), and other information obtained during verification of client identity will be retained for at least 8 years from the date of business relationship termination (according to the applicable legislation, storage time may be extended for a further period on reasonable order from the competent authority, however not exceeding 2 years);</li>
                <li>Business communication with the Customer/Client (including correspondence and recordings of phone conversations) will be retained for at least 5 years since the termination of business relationship, if it is related to the fulfilment of money laundering and terrorist financing prevention requirements. (According to the applicable legislation, storage time may be extended for a further period on reasonable order from the competent authority, however not exceeding 2 years). However, if there is a justified need to retain a specific recording for a longer period, this may be reviewed by the director in conjunction with the Data Protection Officer;</li>
                <li>Documents and data confirming/justifying the validity of monetary/relevant operations and transactions, and other legally valid and relevant information/documentation will be retained for at least 8 years from the execution of monetary/relevant operation or conclusion of the transaction (according to the applicable legislation, storage time may be extended for a further period on reasonable order from the competent authority, however not exceeding 2 years);</li>
                <li>Data to prove the fulfillment of our obligations will be kept for the general limitation period for the requirement, in accordance with the regulatory enactments for limitation periods of claims. For example, depending on the specific circumstances of the situation, the limitation period of 10 years established in applicable laws might be applicable, also taking into account the time limits for submitting claims, set out in the civil law legislation;</li>
                <li>Video surveillance records, other than those listed above, will be retained for 30 days from the date of recording thereof (video surveillance records, in case certain data is issued to investigation bodies/officers and used for investigation of an offensive action according to the procedure of the applicable law, may be stored for a longer period, specified by the relevant regulatory enactments).</li>
            </ul>
            <p>Personal data related to the Application usage may be stored. We continuously process the data from the conclusion of the contract to the termination of the contract. We store the data during the contract and 1 year after the termination thereof. More can be found in our general terms of use.</p>

            <h2>4. WHERE WE KEEP YOUR DATA</h2>
            <p>We only store personal data on our own servers, which we have built in <em>Hetzner Cloud</em>. Our organisation is committed to ensuring the proper protection of personal data, therefore we only store data on servers operated in countries applying and requiring high standards of data protection, IT security, and related matters.</p>

            <h2>5. THIRD-PARTY SERVICE PROVIDERS AS DATA PROCESSORS</h2>
            <p>To ensure the highest level of financial service and to meet regulatory requirements, the following listed activities are provided to our Customers by market participants who can guarantee adequate security for the high quality of the Service. Appropriate security measures are also in place for the processing of personal data.</p>
            <p>Our contracted partners receive the Customer data only to the extent and for the time necessary for the tasks, based on which the activity, service, or product included in the contract is provided to our Customers. The personal data of our Customers will not be transferred for any other purpose, and we expect this from our contracted partners as well.</p>
            <p>Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on the Website, which are sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalise the advertising content that you see on websites that you visit.</p>
            <p>The Policy does not apply to other advertisers or websites. Therefore, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>

            <h2>6. YOUR RIGHTS AND CONTACTING US</h2>
            <p>By contacting us at <a href="mailto:legal@solarenterprises.com">legal@solarenterprises.com</a>, you may exercise your rights as the data subject, including but not limited to the following:</p>
            <ul>
                <li>The right to request access to your personal data. You may access, correct, update, change, or remove your personal data at any time. However, please note that certain information is strictly necessary in order to fulfill the purposes defined in this Policy and may also be required by law. Thus, you may not remove such personal data.</li>
                <li>The right to request rectification of your personal data.</li>
                <li>The right to request the erasure of your personal data. If personal data is erased under your request, we will only retain such copies of the information as are necessary for us to protect our or third parties’ legitimate interests, comply with governmental orders, resolve disputes, troubleshoot problems, or enforce any agreement you have entered into with us.</li>
                <li>The right to withdraw consent regarding the processing of personal data.</li>
                <li>The right to data portability.</li>
            </ul>
            <p>In some cases, you may have a right to request restrictions on the processing of your personal data or to object to the processing of your personal data. If you think there is a problem with the way we are handling your personal data, you have a right to file a complaint with your national data protection authority in the national and/or regional state and/or organisation.</p>
        </div>
    )
}

export default Privacy