import Build from "./sections/Build";
import Hero from "./sections/Hero";
import Paperless from "./sections/Paperless";
import Product from "./sections/Product";
import Visit from "./sections/Visit";
import Footer from "./sections/Footer";
import backgroundCurveImage from "../../assets/backgrounds/background-curve.png";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Solar Enterprises @ BBW</title>
      </Helmet>
      <div className="bg-[#090b0a] bg-contain bg-center bg-no-repeat bg-top" style={{ backgroundImage: `url(${backgroundCurveImage})` }}>
        <Hero />
        <Product />
        <Paperless />
        <Visit />
        <Build />
        <Footer />
      </div>
    </>
  );
};

export default Home;
