import { useTranslation } from "react-i18next";
import { useInView, motion } from "framer-motion";
import { useRef, useState, useEffect } from "react";

import Accordion from "../../../components/Accordion";

export default function FaqSection() {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const [faqs, setFaqs] = useState([]);
  const [showedFaqs, setShowedFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetch("/faq-" + i18n.language + ".json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(response => response.json())
    .then(data => {
      setFaqs(data);
      setShowedFaqs(data.slice(0, 5)); // Initially load up to 5 FAQs
    })
    .catch(error => {
      // console.error('Failed to load FAQs', error);
    });
  }, [i18n.language]);

  const handleAccordionToggle = (index) => {
    if (index !== activeIndex) {
      setActiveIndex(index);
      if (showedFaqs.length < faqs.length) {
        const additionalFaqs = faqs.slice(showedFaqs.length, showedFaqs.length + 3);
        setShowedFaqs(currentFaqs => [...currentFaqs, ...additionalFaqs]);
      }
    }
  };

  return (
    <section className="section pt-5 md:pt-20 text-center w-full">
      <div className="absolute w-screen left-0" ref={ref}>
        <img
          src={"/images/bg/FAQ.webp"}
          alt="Dark FAQ background"
          className="absolute pt-[120px] md:pt-[30px] w-screen max-w-[1600px] left-1/2 -translate-x-1/2 lg:-translate-y-[18%] -z-10 dark:block hidden"
        />
        <img
          src={"/images/bg/FAQLight.webp"}
          alt="Light FAQ background"
          className="absolute pt-[120px] md:pt-[30px] w-screen max-w-[1600px] left-1/2 -translate-x-1/2 lg:-translate-y-[18%] -z-10 block dark:hidden"
        />
      </div>
      <motion.div
        initial={{ opacity: 0.1 }}
        animate={inView ? { opacity: 1 } : {}}
        transition={{ duration: 1.5, delay: 0.9 }}>
        <h3 className="text-[32px] leading-[35.2px] md:text-[48px] md:leading-[47.52px] font-bold">
          {t("faq-title-1")}
          <span style={{ color: "rgb(237, 92, 21)" }}>{t("faq-title-2")}</span>
        </h3>
        <p className="max-w-[625px] mt-6 font-normal text-[14px] leading-[18.2px] md:text-[16px] md:leading-[20.8px] mx-auto">
          {t("faq-desc")}
        </p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 500, scale: 0.8 }}
        animate={inView ? { opacity: 1, y: 0, scale: 1 } : {}}
        transition={{ duration: 1.5, delay: 0.9 }}
        className="max-w-[770px] mt-[145px] md:mt-48 w-full grid gap-3 mx-auto">
        {showedFaqs.map(({ Q, A }, index) => (
          <motion.div
            initial={{
              opacity: (1 / faqs.length) * (faqs.length - index),
            }}
            animate={inView ? { opacity: 1 } : {}}
            transition={{ duration: 1.5 }}
            key={index}
            onClick={() => handleAccordionToggle(index)}>
            <Accordion key={index} question={Q} answer={A} index={index} expanded={index === activeIndex}/>
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
}
