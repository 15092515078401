import React, { useState, useEffect } from "react";
import Arrow from "../../../assets/icons/arrow.jsx";
import { Link } from "react-router-dom";
import formatDate from "../FormatDate/index.jsx";

export default function Article({docs}) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [])
  return (
    <div className="containerAlt px-[20px] md:px-5 lg:w-full sb:px-10 xl:px-36 md:flex justify-between pt-6 md:pb-[26px] gap-4">
      <div className="absolute w-full left-0">
        <img
          src={"/images/bg/blogDark.png"}
          alt="Dark FAQ background"
          className="absolute  w-screen left-1/2 -translate-x-1/2 -translate-y-[20%] -z-10 dark:block hidden"
        />
        {/* <img
          src={"/images/bg/blogDark.png"}
          alt="Light FAQ background"
          className="absolute  w-screen left-1/2 -translate-x-1/2 -translate-y-[20%] -z-10 block dark:hidden"
        /> */}
      </div>

      {
      (docs.posts && docs.posts.length > 0) &&
      <div className="z-10 mb-8 rounded-[16px] md:h-[515px] md:w-1/2 lg:w-[600px] hover:rounded-2xl sm:p-1 hover:border-white/10 hover:bg-white/10">
        <Link to={`/blogs/${docs.posts && docs.posts[0].slug}`}>
          <div className="relative pb-[22px] sm:pb-0  md:h-[320px] ">
            <img
              src="/images/icons/category.svg"
              alt=""
              className="absolute pl-4 pt-4 object-cover object-center rounded-lg hover:cursor-pointer"
            />
            <img
              src={docs.posts && docs.posts[0].feature_image}
              alt=""
              className="w-full h-[180px] sm:w-full sm:h-[300px] md:h-[320px] object-cover object-center rounded-[16px] hover:cursor-pointer"
            />
          </div>
          <div className="w-full px-auto md:pt-[22px] lg:w-[600px]">
            <time className="text-sm">
              {docs.posts && formatDate(docs.posts[0].published_at)}
            </time>
            <div className="line-clamp-2 py-2 text-[24px] lg:text-[30px] xl:text-[32px] ">
              {docs.posts && docs.posts[0].title}
            </div>
            <div
              className="line-clamp-2 text-sm opacity-80"
              dangerouslySetInnerHTML={
                docs.posts && { __html: docs.posts[0].excerpt ?? "" }
              }
            />
          </div>
          <div className="flex gap-2 pt-4">
            <img
              src={"/images/icons/24x24_solar.svg"}
              alt=""
              className="h-6 w-6 object-cover object-center rounded-lg hover:cursor-pointer"
            />
            <span className="text-[#ED5C15]">By Solar Enterprises</span>
          </div>
        </Link>
      </div>
      }
      <div className="w-[343px] sm:w-full md:w-1/2 lg:full sm:pr-2 md:pr-3 flex flex-col gap-4 sm:gap-2 z-10 lg:h-[514px] ">
        {docs.posts &&
          docs.posts.map((post, index) =>
            index > 0 && index < 6 ? (
              <Link key={index} to={`/blogs/${post.slug}`}>
                <div className="relative mx-auto lg:w-full flex hover:rounded-[8px] p-1 hover:border-white/10 hover:bg-white/10">
                  <img
                    src={post.feature_image} 
                    alt=""
                    className="h-[90px] w-[90px] object-cover object-center rounded-[10px] hover:cursor-pointer"
                  />
                  <div className="px-4 max-h-[122px] lg:max-w-[501px] lg:max-h-[90px]">
                    <div className="font-nunito text-sm font-normal leading-[130%] opacity-40">{formatDate(post.published_at)}</div>
                    <div className="line-clamp-2 py-1 text-lg lg:text-2xl md:line-clamp-1">{post.title}</div>
                    <div className="items-center gap-2 hidden md:flex ">
                      <img
                        src="/images/icons/category.svg"
                        alt=""
                        className="h-[32px] w-[100px] object-cover object-center rounded-lg hover:cursor-pointer"
                      />                            
                      <div className="md:flex gap-2">
                        <img
                          src="/images/icons/24x24_solar.svg"
                          alt=""
                          className="h-[24px] w-[24px] object-cover object-center rounded-lg hover:cursor-pointer"
                        />
                        <div className="text-[#ED5C15] text-base">
                          By Solar Enterprises
                        </div>
                      </div>
                    </div>
                  </div>
                  <Arrow className="hover:dark:fill-white hover:fill-theme-bg fill-theme-orange absolute top-0 right-0" />
                </div>
                <div className="md:hidden h-8 w-[343px] mx-auto flex items-center gap-4 pt-5 ">
                  <img
                    src="/images/icons/category.svg"
                    alt=""
                    className="h-8 w-[90px] object-center rounded-lg hover:cursor-pointer"
                  />
                  <div className="flex gap-2">
                    <img
                      src="/images/icons/24x24_solar.svg"
                      alt=""
                      className="h-[24px] w-[24px] object-cover object-center rounded-lg hover:cursor-pointer"
                    />
                    <span className="text-[#ED5C15]">
                      By Solar Enterprises
                    </span>
                  </div>
                </div>
              </Link>
            ) : (
              ""
            )
          )}
      </div>
    </div>
  );
}
