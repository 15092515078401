import cn from "../../utilities/cn";
import { useState } from "react";
export default function Button({ type, href, onClick, className, children, target, rel, hoverImg }) {
  const [isHover, setIsHover] = useState(false);
  function Wrapper() {
    if (type === "a") {
      return (
        <>
          {hoverImg && (
            <div
              style={{ backgroundImage: isHover ? `url(${hoverImg})` : "none", backgroundSize: "100% 100%" }}
              className={`w-fit bg-no-repeat bg-center mx-auto h-[64px] px-[10px] flex items-center justify-center my-btn z-[1000]`}
            >
              <a
                href={href}
                target={target}
                rel={rel}
                onMouseOver={() => {
                  setIsHover(true);
                }}
                onMouseLeave={() => {
                  setIsHover(false);
                }}
                className={cn(
                  "mx-auto h-[48px] text-[16px] leading-[16px] bg-theme-orange font-semibold text-white rounded-full py-2 pl-[20px] pr-2 flex gap-4 items-center z-[1000]",
                  className
                )}
              >
                {children}
              </a>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          {hoverImg && (
            <div
              style={{ backgroundImage: isHover ? `url(${hoverImg})` : "none", backgroundSize: "100% 100%" }}
              className={`w-fit bg-no-repeat bg-center mx-auto h-[64px] px-[10px] flex items-center justify-center my-btn z-[1000]`}
            >
              <button
                onMouseOver={() => {
                  setIsHover(true);
                }}
                onMouseLeave={() => {
                  setIsHover(false);
                }}
                className={cn("mx-auto bg-theme-orange font-semibold text-white rounded-full py-2 pl-5 pr-2 w-fit flex gap-4 items-center z-[1000]", className)}
                onClick={onClick}
              >
                {children}
              </button>
            </div>
          )}
        </>
      );
    }
  }
  return <Wrapper />;
}
