import clsx from "clsx";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import checkIcon from "../../assets/icons/check.png";
import { useDispatch } from "react-redux";
import { setLN } from "../../feature/mode/changeLanguage";

const LANGUAGE_SELECTOR_ID = 'language-selector';
export default function LangSelector({isMobile}) {
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const dropdownMobileRef = useRef(null);
    const dropdownMenuMobileRef = useRef(null);
    const {i18n, t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [languages, setLanguages] = useState([{key:'en',name:'EN'}, {key:'jp',name:'JP'}]);
    const handleLanguageChange = async (language) => {
        await i18n.changeLanguage(language.key);
        localStorage.language = language.key
        setIsOpen(false);
    };
    const selectedLanguage = languages.find(language => language.key === i18n.language);
    useEffect(() => {
        if (localStorage.language)
            i18n.changeLanguage(localStorage.language).then(() => {
                dispatch(setLN(localStorage.language))
            });
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        function mobileHandleClickOutside(event) {
            if ((dropdownMobileRef.current && !dropdownMobileRef.current.contains(event.target)) && (dropdownMenuMobileRef.current && !dropdownMenuMobileRef.current.contains(event.target))) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('click', mobileHandleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('click', mobileHandleClickOutside);
        };
    }, []);
    return (
        <>
        <div className="flex items-center z-40">
            <div className="relative inline-block text-left w-full">
                <div className="w-full">
                    <button
                        ref={dropdownMenuMobileRef}
                        onClick={() => setIsOpen(!isOpen)}
                        type="button"
                        className={`${(!isMobile && isOpen)? "rounded-tr-[24px] rounded-tl-[24px] border-l border-r border-t ":" rounded-[100px] border "}bg-[#F7F7F7] dark:bg-[#141417] border-opacity-25 border-black/20 dark:border-white/20 h-[48px] ${isMobile? "w-full":"w-[118px]"} relative flex justify-center items-center transition-all duration-200 ease-in-out`}
                        id={LANGUAGE_SELECTOR_ID}
                        aria-haspopup="true"
                        aria-expanded={isOpen}
                    >
                      <div className="flex justify-between w-full px-[17px]">
                        <div>
                          <FlagIcon countryCode={selectedLanguage.key}/>
                          {selectedLanguage.name}
                        </div>
                        {!isMobile ?
                            <svg
                                className={`-mr-1 ml-2 h-5 w-5 ${isOpen? "transform rotate-180": ""}`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            :
                            <svg
                                className={`-mr-1 ml-2 h-5 w-5 transform rotate-180`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        }
                      </div>
                    </button>
                </div>
                {(isOpen && !isMobile) && <div
                    ref={dropdownRef}
                    className={`origin-top-right absolute rounded-br-[24px] rounded-bl-[24px] right-0 ${isMobile? "w-full -mt-[130px]":"w-[118px]"} rounded-md shadow-lg`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="language-selector"
                >
                    <div className="flex flex-col rounded-br-[24px] rounded-bl-[24px]" role="none">
                        {languages.map((language, index) => {
                            return (
                                <button
                                    key={language.key}
                                    onClick={() => handleLanguageChange(language)}
                                    className={`${
                                        index === languages.length - 1
                                            ? "rounded-br-[24px] rounded-bl-[24px] border-b":""
                                    } block h-[48px] justify-between bg-[#F7F7F7] dark:bg-[#141417] px-4 py-2 text-sm text-left items-center inline-flex dark:hover:bg-[#2D1710] hover:bg-[#C1C1C1] border-r border-l border-black/20 dark:border-white/20 border-opacity-25`}
                                    role="menuitem"
                                >
                                    <div className="flex gap-[5px]">
                                        <FlagIcon countryCode={language.key}/>
                                        <span className="truncate dark:text-white text-black">{language.name}</span>
                                    </div>
                                    {selectedLanguage.key === language.key && <img src={checkIcon} alt="" />}
                                </button>
                            );
                        })}
                    </div>
                </div>}
                {(isOpen && isMobile) && 
                    <>
                    <div className="w-full h-full bg-[#00000060] flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div ref={dropdownMobileRef} className="absolute bottom-0 w-full p-[20px] bg-[#000000] dark:bg-[#FFFFFF] flex flex-col rounded-tr-[16px] rounded-tl-[16px]">
                            <div className="text-[24px] leading-[26.4px] text-white dark:text-black font-bold">
                                {t('choose-language')}
                            </div>
                            <div className="flex flex-col p-[10px] gap-[10px]">
                                {languages.map((language, index) => {
                                    return (
                                        <button
                                            key={language.key}
                                            onClick={() => handleLanguageChange(language)}
                                            className={`${
                                                selectedLanguage.key === language.key
                                                    ? "":""
                                            } block justify-between dark:bg-[#FFFFFF] bg-[#000000] px-4 py-2 text-black text-[16px] leading-[16px] text-left items-center inline-flex`}
                                            role="menuitem"
                                        >
                                            <div className="flex gap-[5px]">
                                                <FlagIcon countryCode={language.key}/>
                                                <span className="truncate dark:text-black text-white">{language.name}</span>
                                            </div>
                                            {selectedLanguage.key === language.key && <img src={checkIcon} alt="" />}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                  </>
                }
            </div>
        </div>
        </>
    );
}
function FlagIcon({countryCode = ""}) {

  if (countryCode === "en") {
      countryCode = "gb";
  }

  return (
      <span
          className={`fi fis inline-block mr-2 fi-${countryCode}`}
      />
  );
}
