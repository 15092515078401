import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mode: null,
}

export const changeMode = createSlice({
    name: 'mode',
    initialState,
    reducers: {
      changeTheme: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        if (state.mode === "light") state.mode = "dark";
        else state.mode = "light";
      },
      setTheme: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.mode = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { changeTheme, setTheme } = changeMode.actions

export default changeMode.reducer