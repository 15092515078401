export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const contact_content = (name, email, subject, message) => {
  return `<!DOCTYPE html>
    <html>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                background-color: #f4f4f4;
            }
            .container {
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
                background-color: #ffffff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            .header {
                background-color: #007bff;
                color: #ffffff;
                text-align: center;
                padding: 10px 0;
            }
            .content {
                padding: 20px;
            }
            .footer {
                background-color: #f1f1f1;
                text-align: center;
                padding: 10px 0;
                font-size: 12px;
                color: #777777;
            }
            .message {
                background-color: #f9f9f9;
                border-left: 5px solid #007bff;
                padding: 10px;
                margin: 20px 0;
                line-height: 1.6;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="header">
                <h1>New Message Received</h1>
            </div>
            <div class="content">
                <p>You have received a new message:</p>
                <p>Name: ${name}</p>
                <p>Email: ${email}</p>
                <p>Subject: ${subject}</p>
                <div class="message">
                    <p>${message}</p>
                </div>
                <p>Best regards</p>
            </div>
        </div>
    </body>
    </html>
    `
}