export default function formatDate(date) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const now = new Date(date).toLocaleDateString('en-US', options);
  
    return now;
  }
  