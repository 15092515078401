export default function Arrow(args) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 17 16"
      fill="white"
      {...args}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M5.45066 12.2426L11.1877 6.50565L11.1877 11.776L12.5217 11.7712V4.22876H4.97926L4.97926 5.55812L10.2448 5.56284L4.50786 11.2998L5.45066 12.2426Z" />
    </svg>
  );
}
