import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: null,
}

export const changeContactModal = createSlice({
    name: 'contactModal',
    initialState,
    reducers: {
        toggleOpen: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.open = !state.open;
      },
    },
})

// Action creators are generated for each case reducer function
export const { toggleOpen } = changeContactModal.actions

export default changeContactModal.reducer