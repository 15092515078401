export default function Global({mode, isHover, className}) {
    let color = "white";
    color = mode == 'light'? (!isHover? "black": "white"): "white";
    return (
      <svg 
        width="12" 
        height="13" 
        viewBox="0 0 12 13" 
        fill="none"  
        className={className}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9995 6.49976C10.9995 7.1564 10.8702 7.80662 10.6189 8.41327C10.3676 9.01993 9.9993 9.57116 9.53498 10.0355C9.07067 10.4998 8.51944 10.8681 7.91279 11.1194C7.30613 11.3707 6.65592 11.5 5.99927 11.5C5.34263 11.5 4.69242 11.3707 4.08576 11.1194C3.4791 10.8681 2.92788 10.4998 2.46356 10.0355C1.99925 9.57116 1.63093 9.01993 1.37964 8.41327C1.12836 7.80662 0.999023 7.1564 0.999023 6.49976C0.999023 5.17361 1.52583 3.90178 2.46356 2.96405C3.40129 2.02632 4.67313 1.49951 5.99927 1.49951C7.32542 1.49951 8.59725 2.02632 9.53498 2.96405C10.4727 3.90178 10.9995 5.17361 10.9995 6.49976Z" 
            stroke={color}
        />
        <path d="M7.99905 6.5C7.99905 7.1565 7.94705 7.807 7.84655 8.4135C7.74655 9.02 7.59905 9.571 7.41305 10.0355C7.22755 10.5 7.00705 10.868 6.76455 11.1195C6.52155 11.3705 6.26155 11.5 5.99905 11.5C5.73655 11.5 5.47655 11.3705 5.23405 11.1195C4.99105 10.868 4.77055 10.4995 4.58505 10.0355C4.39905 9.571 4.25155 9.0205 4.15105 8.4135C4.04853 7.78085 3.9977 7.1409 3.99905 6.5C3.99905 5.8435 4.05055 5.193 4.15105 4.5865C4.25155 3.98 4.39905 3.429 4.58505 2.9645C4.77055 2.5 4.99105 2.132 5.23355 1.8805C5.47655 1.63 5.73655 1.5 5.99905 1.5C6.26155 1.5 6.52155 1.6295 6.76405 1.8805C7.00705 2.132 7.22755 2.5005 7.41305 2.9645C7.59905 3.429 7.74655 3.9795 7.84655 4.5865C7.94755 5.193 7.99905 5.8435 7.99905 6.5Z" 
            stroke={color}
        />
        <path d="M1 6.5H11" 
            stroke={color} strokeLinecap="round"
        />
      </svg>
    );
}
  