import React, { useState, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes, StaticRouter } from "react-router-dom";
import DotLoader from "react-spinners/DotLoader";
import Layout from "./layout";
import { shouldPreloadedImages } from "./config/const";
import HomePage from "./pages/Home";
import routes from "./route";
import News from "./pages/News";
import Privacy from "./pages/Privacy";
import Term from "./pages/Term";
import BlogDetails from "./pages/News/BlogDetails";
import BbwDubai from "./pages/BbwDubai";

function preloadImages(images) {
  const promises = images.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  });
  return Promise.all(promises);
}
function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    preloadImages(shouldPreloadedImages)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        // console.error("Error loading images: ", error);
        setLoading(false); // Optionally handle errors more gracefully
      });
  }, []);
  if (loading) {
    return (
      <div className="flex items-center justify-center h-[100vh]">
        <DotLoader
          size={60} // Size of the loader
          color={"#F64A28"} // Color of the loader
          loading={loading} // Pass the loading state
        />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/blogs/:slug" element={<BlogDetails />} />
          <Route path="/blogs" element={<News />} />
          <Route path="/terms" element={<Term />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/bbwdubai" element={<BbwDubai />} />
          <Route path="/bbw" element={<BbwDubai />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Layout>
      {/* <Route path="/#products" element={< />} />
          <Route path="/#team" element={< />} />
          <Route path="/docs" element={< />} /> */}
      {/* {routes.map((route, index) => {
            const Element = route.component;
            return (
              <Route
                path={route.href}
                element={<Element route={route} />}
                key={index}
              />
            );
          })} */}
      {/* </Route> */}
    </BrowserRouter>
  );
}

export default App;
