import { useState } from "react";
import Button from "../../../../components/Button";
import { Trans, useTranslation } from "react-i18next";
import { TransComponents } from "../../../../local/i18n";
import ContactUs from "../../../../components/ContactUs";
import { useDispatch } from 'react-redux';
import { toggleOpen } from "../../../../feature/mode/contactModal";

export default function TopArea() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="grid gap-4 md:gap-6 mt-[80px] px-[10px] md:px-[25px]">
      <h1 className="leading-[35.2px] md:leading-[47.52px] font-bold text-[32px] sm:text-[32px] md:text-[48px] lg:text-[64px]">
        <Trans
            components={{
              ...TransComponents
            }}>
            {t("craft-title")}
        </Trans>
      </h1>
      <p className="font-normal text-[14px] leading-[18.2px] md:text-[16px] md:leading-[20.8px] md:w-[33.875vw] mx-auto">
        {t("craft-desc")}
      </p>
      <Button 
        type="button"
        hoverImg={"/images/shapes/inspired-hover.svg"}
        onClick={() => {
          dispatch(toggleOpen())
          document.body.style.overflow = 'hidden';
        }}
      >
        {t("contact-us")}
        <span>
          <img src={'/images/icons/arrow-up-right.svg'} alt="" className="bg-white rounded-full" />
        </span>
      </Button>
      <ContactUs />
    </div>
  );
}
