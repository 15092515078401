export default function FilterTop({color, className}) {
    return (
        <svg width="344" height="80" className={className} viewBox="0 0 344 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="344" height="80" fill="url(#paint0_linear_1031_13915)"/>
            <defs>
                <linearGradient id="paint0_linear_1031_13915" x1="172" y1="0" x2="172" y2="80" gradientUnits="userSpaceOnUse">
                <stop stopColor={color}/>
                <stop offset="1" stopColor={color} stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    );
}
  