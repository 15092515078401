import heroBgLightImage from "../../../assets/backgrounds/hero-bg-light.png";
import Header from "./Header";

const Hero = () => {
  return (
    <div className="relative bg-cover bg-center w-[100vw] h-full pt-[30vw] sm:pt-0">
      <div className="bg-top bg-no-repeat w-full bg-cover min-h-screen" style={{ backgroundImage: `url(${heroBgLightImage})` }}>
        <Header />
        <div className="flex flex-col justify-center items-center w-full">
          <div className="max-w-[1280px] px-[24px]">
            <p className="mt-[35vh] lg:mt-[28vw] text-center text-[34px] lg:text-[64px] text-white font-bold leading-none">
              Welcome to <br className="md:hidden block" /><span className="text-orange-600">Solar Enterprises</span>{" "}
              <br className="md:block hidden" />
              at Binance Blockchain Week Dubai 2024
            </p>
          </div>
          <div className="max-w-[916px] px-[24px] mt-[20px]">
            <p className="text-center text-white font-thin md:text-[20px] text-[15px] max-w-[916px] leading-[22px] px-4">
              We are Solar Enterprises, and we are here to lead the way toward a brighter, smarter, and more sustainable blockchain future. Built on the Solar
              Blockchain— a fast, eco-friendly Delegated Proof of Stake (DPoS) network—delivering cutting-edge solutions that make crypto secure, efficient, and
              accessible for everyone.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
