import React from 'react';
import NestedSelect from "nested-select";

const CustomSelect = ({ options, isDarkMode, value, setSelect, placeholder }) => {
    const onSubjectSelect = (selected) => {
      setSelect(selected);
    }
    return <NestedSelect
      onSelect={onSubjectSelect}
      options={options}
      searchPlaceholder={placeholder}
      defaultText={
        <div className='text-gray-500'>
          {placeholder}
        </div>
      }
    />
  };
  
  export default CustomSelect;