export default function TwitterIcon(args) {
  return (
    <svg 
      width="40" 
      height="40" 
      viewBox="0 0 40 40" 
      fill="none" 
      {...args}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M25.75 11H28.8171L22.1171 18.6239L30 29H23.8286L18.9914 22.7082L13.4629 29H10.3929L17.5586 20.8427L10 11.0014H16.3286L20.6943 16.7513L25.75 11ZM24.6714 27.1728H26.3714L15.4 12.7322H13.5771L24.6714 27.1728Z"/>
    </svg>
  );
}
