import React from "react";
import clsx from "clsx";
import ToogleDarkMode from "../ToogleDarkMode";
import closeIcon from "../../assets/icons/close.png";
import Logo from "../../assets/logo.png";
import LogoLight from "../../assets/logo-light.png";
import routes from "../../route";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LangSelector from "../LangSelector";
import HeroBackgroundMobile from "../../assets/backgrounds/hero-mobile.svg";
import HeroLightBackgroundMobile from "../../assets/backgrounds/hero-mobile-light.svg";

export default function Drawer({ isOpen, setIsOpen }) {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.theme.mode);
  return (
    <main
      className={
        "w-full z-[100] m-h-[100vh] fixed overflow-hidden bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? " transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all opacity-0 translate-x-full  ")
      }
    >
      <section
        className={
          "w-full w-screen right-0 absolute bg-white h-full shadow-xl duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-x-0 " : "delay-800 translate-x-full ")
        }
      >
        <div className="relative w-full h-full dark:bg-[#000000]">
          <img
            src={
              theme === "light"
                ? HeroLightBackgroundMobile
                : HeroBackgroundMobile
            }
            alt=""
            className="w-full h-full absolute left-0 top-0 object-cover object-center"
          />
          <div className="absolute flex flex-col justify-between z-10 p-[20px] w-full h-full">
            <div>
              <div className="flex gap-8 items-center justify-between w-full">
                <div className="flex gap-6 items-center">
                  <img
                    src={closeIcon}
                    alt=""
                    className="w-[20px] h-[20px]"
                    onClick={() => {
                      setIsOpen(false);
                      document.body.style.overflow = "auto";
                    }}
                  />
                  {/* Dark theme */}
                  <a href="/" className="dark:block hidden">
                    <img src={Logo} alt="" className="h-[30px]" />
                  </a>
                  {/* Light Theme */}
                  <a href="/" className="dark:hidden">
                    <img src={LogoLight} alt="" className="h-[30px]" />
                  </a>
                </div>
                <div>
                  <ToogleDarkMode />
                </div>
              </div>
              <div className="mt-[70px] px-5">
                <ul className="flex flex-col gap-10">
                  {routes.map(({ title, href }, index) => (
                    <div key={`blog-details-${index}`}>
                      {title !== "menu-news-details" &&
                        title !== "menu-news-blogdetails" && (
                          <NavBtn
                            title={title}
                            href={href}
                            setIsOpen={setIsOpen}
                          />
                        )}
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-full">
              <LangSelector isMobile={true} />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

function NavBtn({ title, href, setIsOpen }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <li
      onClick={() => {
        console.log(title, href);

        setIsOpen(false);

        if (title !== "menu-docs") {
          navigate(href);
        } else {
          console.log('href', href);
        }


        document.body.style.overflow = "auto";
      }}
      className={clsx(
        "hover:text-theme-orange font-bold text-base transition-all duration-200 ease-in-out",
        pathname === href && "text-theme-orange"
      )}
    >
      {title !== "menu-news-details" && title !== "menu-news-blogdetails" && (
        <div className="flex gap-1 items-center text-[24px] leading-[26.4px] justify-between">
          {title == "menu-docs" ? (
            <a className="flex items-center justify-between w-full" target="_self" href='https://solar.org/core#documentation'>
              {t(title)}

              <img
                src={"/images/icons/new-tag.svg"}
                alt=""
                className="fill-theme-orange dark:block hidden"
                width={16}
                height={16}
              />
              <img
                src={"/images/icons/new-tag-light.svg"}
                alt=""
                className="fill-theme-orange dark:hidden"
                width={16}
                height={16}
              />
            </a>
          ) : (
            <>{t(title)}</>
          )}
        </div>
      )}
    </li>
  );
}
