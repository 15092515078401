import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  width: null,
}

export const handleWidth = createSlice({
    name: 'width',
    initialState,
    reducers: {
      changeWidth: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
      },
    },
})

// Action creators are generated for each case reducer function
export const { changeWidth } = handleWidth.actions

export default handleWidth.reducer