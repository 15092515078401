import { useEffect, useState } from "react";

import clsx from "clsx";
import cn from "../../utilities/cn";
import { useDispatch } from 'react-redux';
import { changeTheme, setTheme } from "../../feature/mode/changeMode";
import { useSelector } from 'react-redux';

export default function ToogleDarkMode() {
  const dispatch = useDispatch();
  const [currectMode, setCurrentMode] = useState(null);
  const theme = useSelector((state) => state.theme.mode);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (theme) {
      setCurrentMode(theme)
    } else {
      if (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
      ) {
        document.documentElement.classList.add("dark");
        setCurrentMode("dark");
        dispatch(setTheme("dark"));
      } else {
        document.documentElement.classList.remove("dark");
        setCurrentMode("light");
        dispatch(setTheme("light"));
      }
    }
  }, [theme]);

  function toogleDarkMode() {
    dispatch(changeTheme());
    setCurrentMode(mode => {
      if (mode === "light") {
        localStorage.theme = "dark";
        document.documentElement.classList.add("dark");
        return "dark";
      } else {
        localStorage.theme = "light";
        document.documentElement.classList.remove("dark");
        return "light";
      }
    });
  }
  return (
    <button
      className={cn(
        "h-12 w-12 relative rounded-full border border-white border-opacity-25 flex justify-center items-center transition-all duration-200 ease-in-out",
        currectMode === "dark" ? "bg-[#FFFFFF08]" : "bg-theme-orange"
      )}
      onMouseOver={() => {setIsHover(true)}}
      onMouseLeave ={() => {setIsHover(false)}}
      onClick={toogleDarkMode}>
      {currectMode === "dark" ? (
        <img src={"/images/icons/moon.svg"} alt="" width={24} height={24} className="h-6 w-6" />
      ) : (
        <img src={"/images/icons/sun.svg"} alt="" width={24} height={24} className="w-6 h-6" />
      )}
      <div style={{ backgroundImage: isHover?`url(${"/images/shapes/header-hover.svg"})`:"none"}} className={`absolute h-[64px] w-[64px] bg-no-repeat bg-center flex items-center justify-center`}></div>
    </button>
  );
}
