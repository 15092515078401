import { useRef } from "react";
import { useInView, motion } from "framer-motion";

import { DifferenceData } from "../../../config/const";
import { Trans, useTranslation } from "react-i18next";
import { TransComponents } from "../../../local/i18n";
import { useSelector } from 'react-redux';

export default function WhatsDifferentSection() {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const { t } = useTranslation();
  const theme = useSelector((state) => state.theme.mode);
  return (
    <section className="containerAlt flex flex-col items-center relative text-white md:w-[80vw]">
      <img
        src={"/images/shapes/WhatsDifferent.webp"}
        alt=""
        width={1280}
        height={750}
        className="mx-auto object-cover -z-10 top-0 left-0 hidden md:hidden dark:md:block"
      />
      <img
        src={"/images/shapes/WhatsDifferentLight.webp"}
        alt=""
        width={1280}
        height={750}
        className="mx-auto object-cover -z-10 top-0 left-0 hidden md:block dark:md:hidden"
      />
        <img
          src={theme === "light" ? "/images/shapes/WhatsDifferentMobileLight.svg": "/images/shapes/WhatsDifferentMobile.svg"}
          alt=""
          width={375}
          height={750}
          className="mx-auto object-cover -z-10 top-0 left-0 md:hidden dark:md:hidden"
        />
      <div className="absolute w-[343px] py-12 flex md:w-[80%] px-8 md:top-1/2 md:-translate-y-1/2 dark:bg-theme-bg bg-[#EFEFEF] rounded-2xl overflow-hidden">
        <img
          src={'/images/bg/WhatsDifferentContainerBg.webp'}
          alt=""
          width={1129}
          height={300.453}
          className="mx-auto absolute object-cover -z-10 top-0 left-0 h-full w-full opacity-40 hidden md:block dark:md:hidden"
        />
        <img
          src={'/images/bg/WhatsDifferentContainerBgLight.webp'}
          alt=""
          width={1129}
          height={300.453}
          className="mx-auto absolute object-cover -z-10 top-0 left-0 h-full w-full opacity-40 hidden md:hidden dark:md:block"
        />
        <img
          src={'/images/bg/WhatsDifferentContainerBgMobile.webp'}
          alt=""
          width={343}
          height={820}
          className="mx-auto absolute object-cover top-0 left-0 h-full opacity-40 md:hidden dark:md:hidden"
        />
        <div className="top-0 left-0 z-1 w-full h-full -z-5 absolute md:hidden dark:bg-[linear-gradient(180deg,_rgba(12,_12,_15,_0)_0%,_#0C0C0F_72%)] bg-[linear-gradient(180deg,_rgba(239,_239,_239,_0)_0%,_#EFEFEF_92%)]"></div>
        <div className="md:block hidden absolute z-10 w-full h-full dark:bg-[linear-gradient(180deg,_rgba(12,_12,_15,_0)_0%,_#0C0C0F_78%)] bg-[linear-gradient(180deg,_rgba(239,_239,_239,_0)_0%,_#EFEFEF_78%)] top-0 left-0" />
        <div className="relative z-10 top-0 md:px-4 grid lg:flex h-full gap-10">
          <motion.div
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 0 }}
            transition={{
              duration: 0.9,
              delay: 0.9,
            }}
            className="md:max-w-80 flex w-full text-center flex-col md:text-left justify-between gap-4">
            <h3
              className="font-bold text-[24px] leading-[26.4px] md:text-xl flex-1 dark:text-white text-black"
            >
              {t("what-difference-title-1")}
              <span style={{color: "rgb(237 92 21)"}}>{t("what-difference-title-2")}</span>
            </h3>
            <p className="opacity-60 font-normal text-[14px] leading-[18.2px] md:text-sm flex-1 dark:text-white/80 text-black">
              {t("what-difference-content")}
            </p>
          </motion.div>
          <div className="grid gap-4 lg:grid-cols-2" ref={ref}>
            {DifferenceData.differences.map(
              ({ title, desc, imgSrcDark, imgSrcLight }, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1 } : { opacity: 0 }}
                  transition={{ duration: 0.9, delay: 0.9 }}
                  className="flex items-start">
                  <img src={imgSrcDark} alt="" className="w-[96px] md:w-24 md:h-24 dark:block hidden" />
                  <img src={imgSrcLight} alt="" className="w-[96px] md:w-24 md:h-24 dark:hidden" />
                  <div>
                    <h6 className="text-[20px] leading-[24px] md:text-xl font-bold dark:text-white text-black">{t(title)}</h6>
                    <p className="text-[12px] leading-[14.4px] dark:text-white/80 text-black">
                        <Trans
                          components={{
                            ...TransComponents,
                            a: (
                              <a href="https://solar.org" target="_blank" rel="noreferrer" />
                            ),
                          }}>
                            {t(desc)}
                        </Trans>
                    </p>
                  </div>
                </motion.div>
              )
            )}
          </div>
        </div>
        <div></div>
      </div>
    </section>
  );
}
