import TopArea from "./Top";
import MiddleArea from "./Middle";
import BottomArea from "./Bottom";
import LastArea from "./Last";

import HeroBackground from "../../../assets/backgrounds/hero.svg";
import HeroLightBackground from "../../../assets/backgrounds/hero-light.svg";
import HeroBackgroundMobile from "../../../assets/backgrounds/hero-mobile.svg";
import HeroLightBackgroundMobile from "../../../assets/backgrounds/hero-mobile-light.svg";
import { useSelector } from 'react-redux';

export default function HeroSection() {
  const ln = useSelector((state) => state.lang.language);
  const theme = useSelector((state) => state.theme.mode);
  return (
    <section key={ln} className="section text-center !p-0 overflow-hidden lg:ml-auto lg:mr-auto md:ml-[20px] md:mr-[20px]" id="home">
      <img
        src={theme === "light" ? HeroLightBackground: HeroBackground}
        alt=""
        className="absolute w-full -z-10 object-cover top-0 left-0 md:block hidden"
      />
      <img
        src={theme === "light" ? HeroLightBackgroundMobile: HeroBackgroundMobile}
        alt=""
        className="absolute -z-10 object-cover top-0 left-0 md:hidden w-full"
      />
      <img
        src={"/images/shapes/Ellipse 1.svg"}
        alt=""
        className="absolute -z-10 object-cover top-0 right-0 w-[36.7vw] md:block hidden"
      />
      <img
        src={"/images/shapes/Ellipse 1-mobile.svg"}
        alt=""
        className="absolute -z-10 object-cover top-0 right-0 md:hidden"
      />
      <TopArea />
      <MiddleArea />
      <BottomArea />
      <LastArea />
    </section>
  );
}
