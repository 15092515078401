export default function PrevIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.6145 3.36575C8.37457 3.1372 7.99477 3.14643 7.76622 3.38636L3.76515 7.58674C3.54443 7.81848 3.54443 8.18268 3.76515 8.41444L7.76622 12.6148C7.99477 12.8547 8.37457 12.864 8.6145 12.6354C8.85444 12.4068 8.86367 12.0271 8.63512 11.7871L5.02827 8.0006L8.63512 4.21403C8.86367 3.97409 8.85444 3.59431 8.6145 3.36575ZM11.8145 3.36575C11.5746 3.1372 11.1948 3.14643 10.9662 3.38636L6.96515 7.58674C6.74443 7.81848 6.74443 8.18268 6.96515 8.41444L10.9662 12.6148C11.1948 12.8547 11.5746 12.864 11.8145 12.6354C12.0544 12.4068 12.0637 12.0271 11.8351 11.7871L8.22825 8.0006L11.8351 4.21403C12.0637 3.97409 12.0544 3.59431 11.8145 3.36575Z" />
    </svg>
  );
}
