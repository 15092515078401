import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";

import routes from "../../route";

import Logo from "../../assets/logo.png";
import LogoLight from "../../assets/logo-light.png";
import ToogleDarkMode from "../ToogleDarkMode";
import LangSelector from "../LangSelector";
import MenuToggle from "../../assets/icons/toggle.png";
import LogoMobile from "../../assets/icons/logo.png";
import { useDispatch } from 'react-redux';
import { toggleOpen } from "../../feature/mode/contactModal";

export default function Header({setIsOpen}) {
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);
  const { t } = useTranslation();


  useEffect(() => {
    const scrollHandler = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);
  return (
    <nav
      className={`containerAlt px-[20px] py-[25px] sb:px-10 xl:px-36 flex justify-between w-full md:py-8 sticky top-0 z-50 items-center text-base font-semibold ${
        scrolled ? "backdrop-blur-md" : ""
      }`}>
      <div className="gap-8 sb:flex hidden">
        <ul className="flex gap-10">
          <LogoRender />
          {routes.map(({ title, href }, index) => (<div key={`nav button ${index}`} >
          {
            title !=='menu-news-details' && title !=='menu-news-blogdetails' &&<NavBtn title={title} href={href} />
          }
          </div>
          ))}
        </ul>
      </div>
      <div className="flex w-full sb:w-fit items-center gap-6 justify-between">
        <div className="sb:hidden flex gap-[20px]">
          <img src={MenuToggle} alt="" className="w-[28px] h-[28px]" onClick={() => {
            setIsOpen(true);
            document.body.style.overflow = 'hidden';
          }}/>
          <img src={LogoMobile} className="w-[25.78px] h-[28.2px]" alt="" />
        </div>
        <div className="flex gap-3 sb:gap-6">
          <div className="sb:block hidden">
            <LangSelector isMobile={false}/>
          </div>
          <ToogleDarkMode />
          <button
            onClick={() => {
              dispatch(toggleOpen());
              document.body.style.overflow = 'hidden';
            }}
            className="text-[14px] leading-[14px] md:text-[16px] md:leading-[16px] text-theme-orange border border-theme-orange rounded-full py-1 md:py-2 px-6 hidden outline-none items-center hover:bg-theme-orange hover:text-white transition-all duration-200 ease-in-out">
            <span>{t("contact-us")}</span>
          </button>
        </div>
      </div>
    </nav>
  );
}

function NavBtn({ title, href }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <li
      className={clsx(
        "hover:text-theme-orange text-base transition-all duration-200 ease-in-out",
        pathname === href && "text-theme-orange"
      )}>
      <a
        href={
          href === "/docs"
            ? "https://solar.org/core#documentation"
            : href
           
        }
        target={(href === '/docs')? "_blank": ""}
        rel={(href === '/docs')? "noopener noreferrer": ""}
        className="flex gap-1 items-center">
        {t(title)}
        {(href === "/docs") && (
          <>
            <img
              src={"/images/icons/new-tag.svg"}
              alt=""
              className="fill-theme-orange dark:block hidden"
              width={16}
              height={16}
            />
            <img
              src={"/images/icons/new-tag-light.svg"}
              alt=""
              className="fill-theme-orange dark:hidden"
              width={16}
              height={16}
            />
          </>
        )}
      </a>
    </li>
  );
}

function LogoRender() {
  return (
    <>
      {/* Dark theme */}
      <a href="/" className="dark:block hidden">
        <img
          src={Logo}
          alt=""
          height={30}
          width={118}
          className="w-[118px] h-[30px]"
        />
      </a>
      {/* Light Theme */}
      <a href="/" className="dark:hidden">
        <img
          src={LogoLight}
          alt=""
          height={30}
          width={118}
          className="w-[118px] h-[30px] block"
        />
      </a>
    </>
  );
}
